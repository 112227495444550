import { BookOpen, Clock4, Play, SquareCheckBig, Users } from "lucide-react";
import CountCard from "../../components/CountCard/CountCard";
import { DefaultLayout } from "../../layouts";
import { CourseContentCard, CourseVideoCard } from "./components";
import { JsxElement } from "typescript";

export const CourseDetailsPage = () => {
  const recentActivities: Array<{
    title: string;
    duration: number;
    link: string;
    accessmentType: "quiz" | "production" | "assessment";
  }> = [
    {
      title: `Conflict Management`,
      duration: 50,
      accessmentType: "production",
      link: "/course/2/quiz/2",
    },
    {
      title: `Negotiation power`,
      duration: 60,
      accessmentType: "production",
      link: "/course/2/quiz/2",
    },
    {
      title: `Negotiation power quiz`,
      duration: 9,
      accessmentType: "quiz",
      link: "/course/2/quiz/2",
    },

    {
      title: `Effective Leadership`,
      duration: 90,
      accessmentType: "production",
      link: "/course/2/quiz/2",
    },
    {
      title: `Course assessment`,
      duration: 90,
      accessmentType: "assessment",
      link: "/course/2/quiz/2",
    },
  ];
  return (
    <DefaultLayout>
      <div
        className="relative bg-secondary-300 h-[25rem]"
        style={{
          backgroundImage: `url(https://images.unsplash.com/photo-1568992687947-868a62a9f521?auto=format&fit=crop&w=1350&q=80)`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "400px",
        }}
      >
        <div
          className=" w-full top-0 left-0  flex items-end bg-gradient-to-t from-secondary-300 to-transparent"
          style={{
            height: "400px",
          }}
        >
          <div className="flex flex-col gap-4 px-8 lg:px-12 pb-8">
            <div>
              <span className="bg-primary-50 text-white rounded-2xl px-2 py-1">
                Liderazgo Y Gestión De Personas
              </span>
            </div>
            <h1 className="text-3xl font-bold text-white">EL ASCENSOR</h1>
            <div className="flex gap-8 items-center">
              <div className="flex gap-2">
                <Clock4 color="#fff" width={20} height={20} />
                <span className="text-white text-sm">2h 30min</span>
              </div>
              <div className="flex gap-2">
                <Users color="#fff" />
                <span className="text-white text-sm">2345 estudiantes</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* course section */}
      <div className="lg:px-14 pt-12 pb-6 blog lg:flex gap-6 bg-secondary-300 rounded-xl">
        <div className=" w-full lg:w-[65%] border-solid boreder-primary border">
          <CourseVideoCard
            videoUrl=""
            description="Liderazgo Y Gestión De Personas"
            title={"EL ASCENSOR"}
            poster="https://images.unsplash.com/photo-1568992687947-868a62a9f521?auto=format&fit=crop&w=1350&q=80"
          />
        </div>
        <div className="w-full lg:w-[35%] mt-6 lg:mt-0 border-solid boreder-primary border">
          <div className="bg-black rounded-md w-full">
            <h2 className="text-lg text-white font-bold  p-6">
              Contenido del curso
            </h2>
            <div className="flex flex-col gap-6 px-6 pb-6">
              {recentActivities.map((recentActivity, index: number) => (
                <CourseContentCard {...recentActivity} key={index} />
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Recent activity session */}
    </DefaultLayout>
  );
};
