import dayjs from "dayjs";
import { Console } from "node:console";
import { start } from "node:repl";
import * as yup from "yup";


export const sessionSchema = yup.object({
    learningPath: yup.string().required("Se requiere ruta de aprendizaje"),
    status: yup.string().required("El estado es obligatorio"),
    startAt: yup.string().required("Se requiere finalizar en"),

    date: yup.string().required("La fecha es obligatoria"),
    endAt: yup.string().required("Se requiere comenzar en").test(
        "is-after-startAt",
        "La hora de finalización debe ser posterior a la hora de inicio",
        function (value) {
            const { startAt, date } = this.parent;
            if (!startAt || !value || !date) return true;
            return dayjs(`${dayjs(date).format("YYYY-MM-DD")} ${value}`).isAfter(dayjs(`${dayjs(date).format("YYYY-MM-DD")} ${startAt}`));
        }
    ),
});