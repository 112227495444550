import { AxiosError } from "axios";
import { useLayoutEffect, useRef, useState } from "react";
import { LearningPathDetailDataService } from "../../../data/services/learningPathDetail.data.service";
import { useCaseLearningPathDetail } from "../../../domain/useCases/learningPathDetail";
import { useCaseProductions } from "../../../domain/useCases";
import { useCustomEffect, useNavigation } from "../../hooks";
import { LearningPathDetailModel } from "../../../data/models/learningPathDetail.model";
import { useSelector } from "react-redux";
import { getSessionToken, getSubscriptionStatus, getUser } from "../../../data/dto/selectors";
import { ChapterEntity, LearningPathDetailEntity, OptionsSelectEntity, ProductionEntity, SeasonEntity } from "../../../domain/entities";
import { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import { commentsDataService, dashboardDataService } from "../../../data/services";
import { productionsDataService } from "../../../data/services";
import { useCaseComments, useCaseDashboard } from "../../../domain/useCases";
import { FieldValues } from "react-hook-form";
import toast from "react-hot-toast";
import { actRemoveLearningPath, actSaveLearningPath } from "../../../data/dto/actions/session.action";
import { ProductionCommentsModel } from "../../../data/models";
import { useDispatch } from "react-redux";

export function useLearningPathDetailViewModel() {
  const dispatch = useDispatch();
  const token = useSelector(getSessionToken);
  const user = useSelector(getUser);
  const userSubscription = useSelector(getSubscriptionStatus);
  const [showModalRating, setShowModalRating] = useState(false);
  const [productionOptions, setProductionOptions] = useState<Array<OptionsSelectEntity>>([]);
  const playerRef = useRef<VideoJsPlayer | null>(null);
  const { getQueryParam, navigateTo } = useNavigation();
  const [sizeScreen, setSizeScreen] = useState({ x: 0, y: 0 });
  const { getListDetailLearningPaths, postLearningPath } =
    useCaseLearningPathDetail(LearningPathDetailDataService());
  const { getChapter } = useCaseProductions(productionsDataService())
  const [detailLearningPath, setDetailLearningPath] =
    useState<LearningPathDetailModel>({} as LearningPathDetailModel);
  const [userCanComment, setUserCanComment] = useState(false);

  const [detailGoldMedal, setDetailGoldMedal] =
    useState<LearningPathDetailEntity | null>();
  const [videoPlayerCurrentTime, setVideoPlayerCurrentTime] =
    useState<number>(0);
  const [showLogInModal, setShowLoginModal] = useState(false);
  const [showStartPathModal, setShowStartPathModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [learningProgress, setLearningProgress] = useState<number>(0);
  const [currentProduction, setCurrentProduction] = useState<ProductionEntity>({} as ProductionEntity)
  const [currentProductionNumber, setCurrentProductionNumber] = useState<number>(0)
  const [nextChapter, setNextChapter] = useState<ChapterEntity>()
  const [currentChapter, setCurrentChapter] = useState<ChapterEntity>({} as any)
  const [roles, setRole] = useState<string>("")
  const [similarProductions, setSimilarProductions] = useState<
    Array<ProductionEntity> | []
  >([]);
  const [isProductionSaved, setIsProductionSaved] = useState(false);
  const [userComments, setUserComments] = useState<ProductionCommentsModel>({
    items: [
      // {
      //   user: {
      //     name: "Promise 1",
      //     lastName: "Emosivbe",
      //     email: "Promiseejiro43@gmail.com",
      //     // avatar: "",
      //   },
      //   body: "This production is a nice one 1",
      //   qualification: 23,
      // },
      // {
      //   user: {
      //     name: "Promise 2",
      //     lastName: "Emosivbe",
      //     email: "Promiseejiro43@gmail.com",
      //     // avatar: "",
      //   },
      //   body: "This production is a nice one 2",
      //   qualification: 23,
      // },
      // {
      //   user: {
      //     name: "Promise 3",
      //     lastName: "Emosivbe",
      //     email: "Promiseejiro43@gmail.com",
      //     // avatar: "",
      //   },
      //   body: "This production is a nice one 3",
      //   qualification: 23,
      // },
      // {
      //   user: {
      //     name: "Promise 4",
      //     lastName: "Emosivbe",
      //     email: "Promiseejiro43@gmail.com 4",
      //     // avatar: "",
      //   },
      //   body: "This production is a nice one 4",
      //   qualification: 23,
      // },
      // {
      //   user: {
      //     name: "Promise 5",
      //     lastName: "Emosivbe",
      //     email: "Promiseejiro43@gmail.com 5",
      //     // avatar: "",
      //   },
      //   body: "This production is a nice one 5",
      //   qualification: 23,
      // },
      // {
      //   user: {
      //     name: "Promise 6",
      //     lastName: "Emosivbe",
      //     email: "Promiseejiro43@gmail.com",
      //     // avatar: "",
      //   },
      //   body: "This production is a nice one 6",
      //   qualification: 23,
      // },
      // {
      //   user: {
      //     name: "Promise 7",
      //     lastName: "Emosivbe",
      //     email: "Promiseejiro43@gmail.com",
      //     // avatar: "",
      //   },
      //   body: "This production is a nice one 7",
      //   qualification: 23,
      // },
      // {
      //   user: {
      //     name: "Promise 8",
      //     lastName: "Emosivbe",
      //     email: "Promiseejiro43@gmail.com",
      //     // avatar: "",
      //   },
      //   body: "This production is a nice one 8",
      //   qualification: 23,
      // },
    ],
    currentPage: 0,
    pages: 0,
  })
  const [filteredUserComments, setFilteredUserComments] = useState<ProductionCommentsModel>({} as ProductionCommentsModel)

  const [videoOptions, setVideoOptions] = useState<VideoJsPlayerOptions>({
    controls: true,
    controlBar: {
      pictureInPictureToggle: false,
      volumePanel: false,
      fullscreenToggle: false,
      currentTimeDisplay: true,
    },
    responsive: true,
    fluid: true,
  });
  const [videoTimeLeft, setVideoTimeLeft] = useState<number>(0);

  const [showNotSubscribedModal, setShowNotSubscribedModal] = useState(false);
  const [transactionFinalStatus, setTransactionFinalStatus] = useState<
    "none" | "success" | "error"
  >("none");
  const [showTransactionModal, setShowTransactionModal] = useState(false);

  // Learning paths details starts

  function calculateDurations(data: any) {
    // Initialize accumulators
    let totalDuration = 0;
    let totalTotalChapterDuration = 0;
    // Iterate through the data array
    data.forEach((item: any) => {
      // Add the top-level duration
      if (item.duration !== undefined) {
        totalDuration += item.duration;
      }
      // Add durations from chapters array if available
      if (Array.isArray(item.chapters)) {
        item.chapters.forEach((chapter: any) => {
          if (chapter.progress !== undefined) {
            totalTotalChapterDuration += chapter.progress;
          }
        });
      }
    });

    // Return the results
    return {
      totalDuration,
      totalTotalChapterDuration,
    };
  }



  function successGetListDetailLearningPath(
    learningPath: LearningPathDetailModel
  ) {
    if (learningPath) {
      const currentPro: any = learningPath.productions

      setDetailLearningPath(learningPath);
      if (learningPath.productions) {
        setProductionOptions(learningPath.productions.map((production, index: number) => {
          return { id: index, name: `producción ${index + 1} ${production.title}` }
        }))
        const results = calculateDurations(learningPath.productions);
        setLearningProgress(results.totalTotalChapterDuration * 100 / results.totalDuration)
        setCurrentProduction(currentPro[currentProductionNumber])
        handleNewVideoSource(currentPro[currentProductionNumber] ? currentPro[currentProductionNumber].videoPreview : "", learningPath.image)
        setCurrentProduction(currentPro[currentProductionNumber])
        if (currentPro[currentProductionNumber].chapters) {
          setCurrentChapter(currentPro[currentProductionNumber].chapters[0])
          learningPath.canWatch && onGetChapter(currentPro[currentProductionNumber].chapters[0] ? currentPro[currentProductionNumber].chapters[0].uuid : "")
        }
      }
    }
  }

  function errorGetListDetailLearningPath(e: AxiosError) {
    console.log("errorGetListDetailLearningPath", e);
  }

  // learning paths chapter
  function successGetProductionChapter(chapter: ChapterEntity) {
    if (chapter) {
      console.log(chapter);
      setCurrentChapter(chapter)
      handleNewVideoSource(chapter.videoUrl, chapter.imgPreview)
    }
  }

  function errorGetProductionChapter(e: AxiosError) {
    console.log("errorGetProductionChapter", e);
    toast.dismiss()
    toast.error("Detalles del capítulo no encontrados")
  }

  const changeProduction = (productionNumber: number) => {
    const learningProductions: any = detailLearningPath.productions
    document.documentElement.scrollTo(0, 0);
    setCurrentProduction(learningProductions[productionNumber])
    setCurrentProductionNumber(productionNumber)
    const productionHasChapter = learningProductions[productionNumber].chapters[0]
    if (productionHasChapter) {
      setCurrentChapter(learningProductions[productionNumber].chapters[0])
      onGetChapter(learningProductions[productionNumber].chapters[0].uuid)
    }
  }

  const onGetChapter = (chapterUuid: string) => {
    getChapter({
      success: successGetProductionChapter,
      error: errorGetProductionChapter,
      id: chapterUuid
    })
  }

  const handleNewVideoSource = (source: string, poster?: string) => {
    if (source) {
      setVideoOptions({
        ...videoOptions,
        autoplay: "play",
        sources: [
          {
            src: source,
            type: "application/x-mpegURL",
          },
        ],
        poster: poster ? poster : videoOptions.poster,
      });
    } else {
      setVideoOptions({
        ...videoOptions,
        poster: poster ? poster : videoOptions.poster,
      });
    }
    window.scrollTo(0, 0);
  };

  function successPostLearningPath(e: any) {
  }

  function errorPostLearningPath(e: AxiosError) {
    console.log("errorGetListDetailLearningPath", e);
    if (e?.response?.status === 401) {
      setShowLoginModal(true);
    } else if (e?.response?.status === 403) {
      setShowNotSubscribedModal(true);
    }
  }

  function errorPayMedal(e: AxiosError) {
    console.log("errorPayMedal", e);
  }

  function handleNotSubscribedModal(value: boolean) {
    setShowNotSubscribedModal(value);
  }

  function handleTransactionModal() {
    setShowTransactionModal(false);
  }

  function handleStartPath() {
    if (token) {
      setShowStartPathModal(true);
    } else {
      setShowLoginModal(true);
    }
  }

  const learningPathId = getQueryParam("uuid");
  const handleStartLearningPath = (uuid: string) => {
    postLearningPath({
      success: successPostLearningPath,
      error: errorPostLearningPath,
      learningPathUuid: uuid,
    });
    dispatch(actSaveLearningPath({ learnintPathUuid: uuid }))
    setShowStartPathModal(false);
  };

  function handleRedirectToFirstProduction() {
    // if (detailLearningPath.
    //   productions
    // [0].path?.length) {
    //   navigateTo(`/preview?uuid=${detailLearningPath.
    //     productions
    //   [0].path[0].id}`);
    // }
    setSuccessModal(false);
  }

  const handleTransactionStatus = (status: string) => {
    switch (status) {
      case "success":
        setTransactionFinalStatus(status);
        setShowTransactionModal(true);
        break;
      case "error":
        setTransactionFinalStatus(status);
        setShowTransactionModal(true);
        break;
      default:
        setTransactionFinalStatus("none");
        setShowTransactionModal(false);
        break;
    }
  };

  const handlePlayerReady = (player: VideoJsPlayer) => {
    let intervalProgress: number = 0;
    let intervalCurrentTime: number = 0;
    playerRef.current = player;
    if (userSubscription?.stripeStatus !== "active") {
      player.controlBar.addClass("vjs-hidden-on-unsubscribe");
      player
        .getChildById("vjs-new-control-bar-id")
        ?.addClass("vjs-hidden-on-unsubscribe");
    } else {
      player.controlBar.removeClass("vjs-hidden-on-unsubscribe");
      player
        .getChildById("vjs-new-control-bar-id")
        ?.addClass("vjs-hidden-on-unsubscribe");
    }
    // You can handle player events here, for example:
    player.on("pause", () => {
      const currentTime = player.currentTime();
      const timeLeft = player.duration() - player.currentTime();
      setVideoPlayerCurrentTime(currentTime);
      setVideoTimeLeft(timeLeft);
      player.clearInterval(intervalProgress);
      player.clearInterval(intervalCurrentTime);
    });

    player.on("play", () => {
      if (userSubscription?.stripeStatus !== "active") {
        player.controlBar.addClass("vjs-hidden-on-unsubscribe");
        player
          .getChildById("vjs-new-control-bar-id")
          ?.addClass("vjs-hidden-on-unsubscribe");
      } else {
        player.controlBar.removeClass("vjs-hidden-on-unsubscribe");
        player
          .getChildById("vjs-new-control-bar-id")
          ?.removeClass("vjs-hidden-on-unsubscribe");
      }

      intervalProgress = player.setInterval(() => {
        const currentTime = player.currentTime();
        setVideoPlayerCurrentTime(currentTime);
      }, 5000);
    });

    player.on("dispose", () => {
      const currentTime = player.currentTime();
      setVideoPlayerCurrentTime(currentTime);
      player.clearInterval(intervalProgress);
    });

    player.on("ended", () => {
      const currentTime = player.currentTime();
      setVideoPlayerCurrentTime(currentTime);
      player.clearInterval(intervalProgress);
    });

    player.on("timeupdate", () => {
      const timeLeft = player.duration() - player.currentTime();
      setVideoTimeLeft(timeLeft);
    });
  };

  const handleChapterProgress = (
    chapterData: ChapterEntity,
    currentChapter: ChapterEntity
  ) => {
    if (currentChapter.uuid === chapterData.uuid) {
      // return chapter with the new progress
      return { ...chapterData, progress: Math.floor(videoPlayerCurrentTime) };
    } else {
      return chapterData;
    }
  };

  const handleAllTimeRangesArray = (previewsTimeRanges: number[][]) => {
    const playerOut = playerRef.current;
    const timeRangesLength = playerOut?.played().length
      ? playerOut.played().length
      : 0;
    let currentTimeRanges = [[0, 1]];

    // create current Time Ranges array
    if (timeRangesLength) {
      for (let index = 0; index < timeRangesLength; index++) {
        const currentTimeRange = [
          playerOut?.played().start(index) as number,
          playerOut?.played().end(index) as number,
        ];
        currentTimeRange.length && currentTimeRanges.push(currentTimeRange);
      }
    }

    // Sort the time ranges by the start time
    const finalTimeRangeArray = currentTimeRanges.length
      ? [...currentTimeRanges, ...previewsTimeRanges].sort(
        (a, b) => a[0] - b[0]
      )
      : previewsTimeRanges.sort((a, b) => a[0] - b[0]);

    let finalTimeRangeArraySummary = [];

    //final iteration
    if (finalTimeRangeArray.length > 1 && finalTimeRangeArray[0].length > 1) {
      let previousRange = finalTimeRangeArray[0];

      // Iterate over the sorted time ranges
      for (let index = 1; index < finalTimeRangeArray.length; index++) {
        const currentRange = finalTimeRangeArray[index];

        // If the current range overlaps with the previous range, merge them
        if (currentRange[0] <= previousRange[1]) {
          previousRange = [
            previousRange[0],
            Math.max(previousRange[1], currentRange[1]),
          ];
          // If the current range does not overlap with the previous range, add the previous range to the result array
        } else {
          finalTimeRangeArraySummary.push(previousRange);
          previousRange = currentRange;
        }
      }
      // Add the last previous range to the result array
      finalTimeRangeArraySummary.push(previousRange);
    }
    return {
      timeRanges: finalTimeRangeArraySummary,
    };
  };

  const handleFindCurrentChapterProgress = () => {
    let productionChapters: ChapterEntity[] | [] = []
    let seasonsChaptersAfterNewProgress: SeasonEntity[] | [] = [];

    // mapping all chapters to find the current chapter, and change his progress
    if (currentProduction.chapters?.length) {
      productionChapters = currentProduction.chapters.map((chapter) =>
        handleChapterProgress(chapter, currentChapter)
      );
    }
    // mapping all seasons to find the current chapter, and change his progress
    // if (currentProduction.chapters?.length) {
    //   seasonsChaptersAfterNewProgress = currentProduction.chapters.map(
    //     (currentSeason) => {
    //       // mapping the current seasons chapter to find the current chapter and change his progress
    //       const newSeasonChaptersAfterProgress = currentSeason.chapters.map(
    //         (chapter) => handleChapterProgress(chapter, chapter)
    //       );
    //       return {
    //         ...currentSeason,
    //         chapters: newSeasonChaptersAfterProgress,
    //       };
    //     }
    //   );
    // }

    const newProductionAfterNewProgress = {
      ...currentProduction,
      chapters: productionChapters,
      seasons: [],
    };
    setCurrentProduction(newProductionAfterNewProgress);
  };


  const { postSavedProductionDashboard, postChapterProgressDashboard } =
    useCaseDashboard(dashboardDataService());
  const { getComments, rateProduction } = useCaseComments(
    commentsDataService()
  );

  const onClickRatingButton = () => {
    setShowModalRating(true);
  };

  const onCancelRating = (data: FieldValues) => {
    setShowModalRating(false);
  };

  const handlerSuccessRating = () => {
    setUserCanComment(false);
    setShowModalRating(false);
    toast.success("Calificación ralizada con exito");
  };

  const onSubmitRating = (data: FieldValues) => {
    if (currentProduction) {
      rateProduction({
        productionId: currentProduction.uuid,
        qualification: data.qualification,
        body: data.body,
        success: handlerSuccessRating,
      });
    }
  };

  const handleSaveProduction = () => {
    if (currentProduction) {
      postSavedProductionDashboard({
        productionUuid: currentProduction.uuid,
        success: () => setIsProductionSaved(true),
      });
    }
  };

  useCustomEffect(() => {
    if (currentChapter?.uuid && detailLearningPath.canWatch && currentChapter.uuid) {
      const timeRangesChapter = currentChapter.timeRanges
        ? currentChapter.timeRanges
        : [[0, 1]];
      const { timeRanges } = handleAllTimeRangesArray(timeRangesChapter);
      handleFindCurrentChapterProgress();
      postChapterProgressDashboard({
        progress: Math.floor(videoPlayerCurrentTime),
        chapterUuid: currentChapter.uuid,
        timeRanges,
      });
    }
  }, [videoPlayerCurrentTime]);

  const handleChangeCommentsPage = (event: any, pageNumber: number) => {
    const lastItemNumber = pageNumber * 4
    setFilteredUserComments({ pages: userComments?.pages ? userComments?.pages : 1, items: userComments?.items ? userComments?.items.slice(lastItemNumber - 4, lastItemNumber) : [], currentPage: pageNumber })
  };

  useCustomEffect(() => {
    getListDetailLearningPaths({
      success: successGetListDetailLearningPath,
      error: errorGetListDetailLearningPath,
      learningPathUuid: learningPathId,
    });
    handleTransactionStatus(getQueryParam("status"));
  }, [learningPathId]);


  useLayoutEffect(() => {
    setFilteredUserComments({ pages: userComments?.pages ? userComments?.pages : 1, items: userComments?.items ? userComments?.items.slice(0, 4) : [], currentPage: 1 })
    const handleResize = () => {
      setSizeScreen({ x: window.innerWidth, y: window.innerHeight });
    };

    dispatch(actRemoveLearningPath());

    handleResize();
    window.addEventListener("resize", handleResize);
    setRole(user?.roles[0].slug ? user?.roles[0].slug : "")
    return () => window.removeEventListener("resize", handleResize);

  }, []);

  useCustomEffect(() => {
    if (currentChapter?.uuid && playerRef?.current?.player_) {
      const playerOut = playerRef.current;
      playerOut.ready(function () {
        var promise = playerOut.play();
        if (promise !== undefined) {
          promise
            .then(function () {
              console.log("Autoplay started");
            })
            .catch(function (error) {
            });
        }
      });
      playerOut.currentTime(currentChapter.progress);
    }
  }, [currentChapter]);

  return {
    token,
    roles,
    sizeScreen,
    learningProgress,
    userSubscription,
    detailLearningPath,
    detailGoldMedal,
    handleStartLearningPath,
    showLogInModal,
    setShowLoginModal,
    showStartPathModal,
    setShowStartPathModal,
    showSuccessModal,
    navigateTo,
    handleRedirectToFirstProduction,
    handleNotSubscribedModal,
    showNotSubscribedModal,
    handleStartPath,
    showTransactionModal,
    handleTransactionModal,
    transactionFinalStatus,
    videoOptions,
    currentProduction,
    handleNewVideoSource,
    currentChapter,
    nextChapter,
    handlePlayerReady,
    similarProductions,
    videoTimeLeft,
    isProductionSaved,
    onCancelRating,
    onClickRatingButton,
    onSubmitRating,
    handleSaveProduction,
    showModalRating, userCanComment,
    onChapterClick: onGetChapter,
    changeProduction,
    productionOptions,
    currentProductionNumber,
    handleChangeCommentsPage,
    filteredUserComments
  };
}
