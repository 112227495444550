import { Play } from "lucide-react";
import { FC } from "react";
import { JsxElement } from "typescript";

interface CountCardProps {
  title: string;
  icon: any;
  count: string;
}

 const CountCard: FC<CountCardProps> = ({ title, icon, count }) => {
  return (
    <div className="flex items-center gap-4 w-full px-6 py-8 bg-secondary-100 rounded-md  hover:scale-105">
      {icon}
      <div>
        <span className="text-gray-400 ">{title}</span>
        <h5 className="text-gray-200 font-medium text-xl">{count}</h5>
      </div>
    </div>
  );
};


export default CountCard;