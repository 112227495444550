import { Carousel } from "../../../../components";
import { CourseCard } from "../courseCard";

export const CourseSection = () => {
  const courses = [
    {
      id: "1",
      image:
        "https://images.unsplash.com/photo-1542744094-3a31f272c490?auto=format&fit=crop&w=1350&q=80",
      title: "LA MEJOR ALTERNATIVA",
      rating: 4,
      terms: ["Negociación"],
      productionType: "Película",
    },
    {
      id: "2",
      image:
        "https://images.unsplash.com/photo-1542744094-3a31f272c490?auto=format&fit=crop&w=1350&q=80",
      title: "LA MEJOR ALTERNATIVA",
      rating: 4,
      terms: ["Negociación"],
      productionType: "Película",
    },
  ];

  return (
    <Carousel
      slidesToShow={courses.length == 1 ? 1 : 3}
      slidesToScroll={1}
      infinite={false}
      dots={false}
      centerPadding="65px"
      responsive={[
        {
          breakpoint: 1120,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            arrows: false,
            centerMode: true,
            centerPadding: "65px",
          },
        },
      ]}
    >
      {courses.map((course, index: number) => (
        <CourseCard key={index} {...course} />
      ))}
    </Carousel>
  );
};
