import { Play, SquareCheckBig } from "lucide-react";
import { FC } from "react";

interface ActivityCardProps {
  title: string;
  description: string;
  accessmentType: string;
  time: string;
}

export const ActivityCard: FC<ActivityCardProps> = ({
  description,
  title,
  time,
  accessmentType,
}) => {
  return (
    <div className="flex gap-4 w-full  py-4  ">
      <span className="rounded-full w-10 h-10 bg-gray-500 flex items-center justify-center">
        {accessmentType === "assessments" ? (
          <SquareCheckBig color="#E30613" width={20} height={20} />
        ) : (
          <Play color="#E30613" width={20} height={20} />
        )}
      </span>

      <div className="flex flex-col gap-1">
        <p className="text-gray-300 font-light">{title}</p>
        <p className="text-gray-400 font-medium text-sm">{description}</p>
        <p className="text-gray-500 font-medium text-xs">{time}</p>
      </div>
    </div>
  );
};
