import { CheckSquare } from "lucide-react";
import { useState } from "react";
import { CheckBox, TextField } from "../../../components";

export const MultipleSelectionForm = () => {
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);
  const [correctAnswers, setCorrectAnswers] = useState([
    false,
    false,
    false,
    false,
  ]);

  const handleOptionChange = (index: number, value: string) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const handleCorrectAnswerChange = (index: number) => {
    const newCorrectAnswers = [...correctAnswers];
    newCorrectAnswers[index] = !newCorrectAnswers[index];
    setCorrectAnswers(newCorrectAnswers);
  };

  return (
    <div className="bg-black my-6 p-6 rounded-lg shadow-md space-y-4">
      <div className="space-y-3">
        <div>
          <TextField
            className="relative w-full flex flex-row items-start justify-center rounded-lg border-[1px] outline-none text-base font-normal leading-6 text-black  placeholder-gray-500 placeholder:font-primaryBook mb-8"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Enter your question"
          ></TextField>
          <p className="text-white mb-4">Revisa el campo correcto</p>
        </div>
        <div className="space-y-6">
          {options.map((option, index) => (
            <div key={index} className="flex items-center space-x-2">
              <CheckBox
                onChange={() => {
                  handleCorrectAnswerChange(index);
                }}
                className={""}
                checked={correctAnswers[index]}
              />
              <TextField
                className="relative w-full flex flex-row items-start justify-center rounded-lg border-[1px] outline-none text-base  font-normal leading-6  text-black placeholder-gray-500 placeholder:font-primaryBook"
                // value={question}
                onChange={(e) => handleOptionChange(index, e.target.value)}
                placeholder="Enter your question"
              ></TextField>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
