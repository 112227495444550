import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  StyledDatePicker,
  StyledInputDate,
} from "../../pages/learningPathCalendar/components/DatePicker/DatePicker.styles";
import { FC, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import moment, { Moment } from "moment";
import { ReactComponent as LeftArrow } from "./../../assets/images/leftArrowCalendar.svg";
import { ReactComponent as RightArrow } from "./../../assets/images/rightArrowCalendar.svg";
import { ReactComponent as CalendarIcon } from "./../../assets/images/calendarIcon.svg";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { TextFieldControlled } from "../TextField";
import { FieldError, FieldErrorsImpl, Merge, useForm } from "react-hook-form";
import TimeSelector from "./TimeSelector";
import { StyledHelperText, StyledLabel } from "../TextField/TextField.styles";

enum DaysOfTheWeek {
  do = "Dom",
  lu = "Lun",
  ma = "Mar",
  mi = "Mie",
  ju = "Jue",
  vi = "Vie",
  sá = "Sab",
}

interface DateTimePicker {
  onChangeDate: (value: any) => void;
  shouldDisableDate: (date: unknown) => boolean;
  label?: string;
  required?: boolean;
  id: string;
  textLabelColor?: string;
  defaultValue: Dayjs | any | Moment;
  helperText?:
    | string
    | FieldError
    | Partial<{ type: string | number; message: string }>
    | Merge<FieldError, FieldErrorsImpl<any>>;
  error?: boolean;
}

import { ClickAwayListener } from "@mui/material"; // Import ClickAwayListener

// Inside your component
export const DateTimePicker: FC<DateTimePicker> = ({
  onChangeDate,
  shouldDisableDate,
  id,
  label,
  required,
  textLabelColor,
  defaultValue,
  helperText,
  error,
}) => {
  const [value, setValue] = useState<Dayjs | any | Moment>(defaultValue);
  const [showCalendar, setShowCalender] = useState<boolean>(false);
  const [showTime, setShowTime] = useState<boolean>(false);

  const handleClickAway = () => {
    setShowCalender(false);
    setShowTime(false);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className="relative">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
        <div
          onClick={() => {
            setShowTime(false);
            setShowCalender(true);
          }}
        >
          <div className="flex items-start justify-start pb-1">
            <StyledLabel textLabelColor={textLabelColor} htmlFor={id}>
              {label}
              {!!required && <span className="text-primary-100"> *</span>}
            </StyledLabel>
          </div>
          <StyledInputDate
            value={value}
            onChange={(newValue: any) => {
              if (newValue) {
                setValue(newValue);
                onChangeDate(newValue);
              }
            }}
            renderInput={(params) => <TextField {...params} />}
            inputFormat={"YYYY-MM-DD"}
            components={{ OpenPickerIcon: CalendarIcon }}
            open={false}
          />
        </div>

        {/* Wrap Calendar with ClickAwayListener */}
        {showCalendar && (
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className="absolute z-10">
              <StyledDatePicker
                shouldDisableDate={shouldDisableDate}
                disablePast={true}
                displayStaticWrapperAs="desktop"
                openTo={"day"}
                value={value}
                views={["day"]}
                onChange={(newValue: any) => {
                  if (newValue) {
                    setValue(newValue.format("YYYY-MM-DD"));
                    onChangeDate(dayjs(newValue).format("YYYY-MM-DD"));
                    setShowCalender(false);
                  }
                }}
                dayOfWeekFormatter={(day) =>
                  DaysOfTheWeek[day as keyof typeof DaysOfTheWeek]
                }
                renderInput={(params) => <TextField {...params} />}
                components={{
                  LeftArrowIcon: LeftArrow,
                  RightArrowIcon: RightArrow,
                }}
              />
            </div>
          </ClickAwayListener>
        )}
        <div className="flex items-center justify-start">
          {" "}
          {!!error && !!helperText && (
            <StyledHelperText>{helperText as string}</StyledHelperText>
          )}
        </div>
      </LocalizationProvider>
    </div>
  );
};
