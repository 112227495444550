import { CheckCircle, XCircle } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

interface QuizResultsProps {
  score: number;
  totalQuestions: number;
  correctAnswers?: number;
  incorrectAnswers?: number;
  nextContentId?: string;
  courseId?: string;
}

export function QuizResults({
  score,
  totalQuestions,
  correctAnswers,
  incorrectAnswers,
  nextContentId,
  courseId,
}: QuizResultsProps) {
  const passed = score >= 70;
  const navigate = useNavigate();

  useEffect(() => {}, [passed, nextContentId, courseId, navigate]);

  return (
    <div className="bg-gray-900 rounded-lg p-8 text-white">
      <div className="text-center mb-8">
        {passed ? (
          <CheckCircle className="h-16 w-16 text-green-500 mx-auto mb-4" />
        ) : (
          <XCircle className="h-16 w-16 text-red-500 mx-auto mb-4" />
        )}
        <h2 className="text-2xl font-bold mb-2">
          {passed ? "¡Felicitaciones!" : "Necesitas repasar el contenido"}
        </h2>
        <p className="text-gray-400">
          Has completado la evaluación con un {score}% de aciertos
        </p>
        <p className="text-sm text-gray-400 mt-2">
          {passed
            ? "Serás redirigido al siguiente video en unos segundos..."
            : "Serás redirigido al inicio del curso para repasar el contenido..."}
        </p>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-8">
        <div className="bg-gray-800 p-4 rounded-lg text-center">
          <p className="text-2xl font-bold text-green-500">{correctAnswers}</p>
          <p className="text-sm text-gray-400">Respuestas correctas</p>
        </div>
        <div className="bg-gray-800 p-4 rounded-lg text-center">
          <p className="text-2xl font-bold text-red-500">{incorrectAnswers}</p>
          <p className="text-sm text-gray-400">Respuestas incorrectas</p>
        </div>
      </div>

      <div className="flex justify-center space-x-4">
        {passed && nextContentId ? (
          <Link
            to={`/course/${courseId}/video/${nextContentId}`}
            className="px-6 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
          >
            Continuar con el siguiente video
          </Link>
        ) : (
          <Link
            to={`/course/${courseId}`}
            className="px-6 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
          >
            Volver al inicio del curso
          </Link>
        )}
      </div>
    </div>
  );
}
