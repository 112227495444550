import { useSelector } from "react-redux";
import { LearningPathSessions } from "../../domain/services/learningPathsSession.service";
import { getSessionToken } from "../dto/selectors";
import { httpService } from "../http/services/http";
import { GetLearningPathsSession, RegisterLearningPathsSessions, UnRegisterLearningPathsSession } from "../../domain/services/learningPathsSession.service";
import { Endpoints } from "./endpoints";
export function LearningPathSessionDataService(): LearningPathSessions {
    const token = useSelector(getSessionToken);

    const { get, post } = httpService();
    return {
        async getListLearningPathsSessions({ success, error, learningPathUuid }: GetLearningPathsSession) {

            try {
                const response = await get(`${Endpoints.LearningPathsSessions}/${learningPathUuid}/sessions`)
                if (success) {
                    success(response.data.data)
                }
            } catch (err) {
                if (error) {
                    error(err)
                }
            }
        },

        async postResgisterLearningPathsSessions({ success, error, learningPathUuid }: RegisterLearningPathsSessions) {
            try {
                const response = await post(`${Endpoints.LearningPathsSessions}/${learningPathUuid}/sessions/register`, {});
                if (success) {
                    success(response.data.data.url);
                }
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        },
        async postUnRegisterLearningPathsSessions({ success, error, learningPathUuid }: UnRegisterLearningPathsSession) {
            console.log(learningPathUuid)
            try {
                const response = await post(`${Endpoints.LearningPathsSessions}/${learningPathUuid}/sessions/unregister`, {});
                if (success) {
                    success(response.data.data.url);
                }
            } catch (e) {
                if (error) {
                    error(e);
                }
            }
        }
    }
}