export interface CourseItem {
  id: string;
  title: string;
  duration: string;
  type: "video" | "quiz";
  completed?: boolean;
}

export interface Course {
  id: string;
  title: string;
  category: string;
  duration: string;
  students: number;
  image: string;
  description: string;
  playlist: CourseItem[];
}

export const courses: Record<string, Course> = {
  "1": {
    id: "1",
    title: "LA MEJOR ALTERNATIVA",
    category: "Negociación",
    duration: "1h 45min",
    students: 1234,
    image:
      "https://images.unsplash.com/photo-1542744094-3a31f272c490?auto=format&fit=crop&w=1350&q=80",
    description:
      'Dos conocidos empresarios del mundo "tech" se enfrentan a la negociación más importante de sus carreras.',
    playlist: [
      {
        id: "1",
        title: "Introducción a la Negociación",
        duration: "10:00",
        type: "video",
        completed: true,
      },
      {
        id: "2",
        title: "Quiz: Conceptos Básicos",
        duration: "15:00",
        type: "quiz",
        completed: true,
      },
      {
        id: "3",
        title: "Técnicas Avanzadas",
        duration: "20:00",
        type: "video",
      },
      {
        id: "4",
        title: "Evaluación Final",
        duration: "30:00",
        type: "quiz",
      },
    ],
  },
  "2": {
    id: "2",
    title: "EL ASCENSOR",
    category: "Liderazgo Y Gestión De Personas",
    duration: "2h 30min",
    students: 2345,
    image:
      "https://images.unsplash.com/photo-1568992687947-868a62a9f521?auto=format&fit=crop&w=1350&q=80",
    description:
      "El ascensor de un edificio de oficinas se convierte en un centro de formación para los personajes que lo utilizan a diario.",
    playlist: [
      {
        id: "1",
        title: "Liderazgo en la Práctica",
        duration: "15:00",
        type: "video",
        completed: true,
      },
      {
        id: "2",
        title: "Quiz: Estilos de Liderazgo",
        duration: "20:00",
        type: "quiz",
      },
      {
        id: "3",
        title: "Gestión de Equipos",
        duration: "25:00",
        type: "video",
      },
      {
        id: "4",
        title: "Evaluación de Competencias",
        duration: "30:00",
        type: "quiz",
      },
    ],
  },
};
export interface QuizQuestionInterface {
  id: string;
  type: "multiple-choice" | "single-choice" | "matching-choice";
  question: string;
  options: string[];
  pairedWord?: string[];
  selectedAnswer: { id: string, text: string }[];
  correctAnswer: [{ id: string, text: string }];
  explanation?: string;
}

export interface Quiz {
  id: string;
  courseId: string;
  title: string;
  description: string;
  timeLimit: number; // en minutos
  questions: QuizQuestionInterface[];
}

export const quizzes: Record<string, Quiz> = {
  "2": {
    id: "2",
    courseId: "1",
    title: "Quiz: Conceptos Básicos de Negociación",
    description:
      "Evalúa tu comprensión de los conceptos fundamentales de negociación.",
    timeLimit: 15,
    questions: [
      {
        id: "1",
        type: "multiple-choice",
        question: "¿Cuál es el primer paso en una negociación efectiva?",
        options: [
          "Hacer una oferta agresiva  Identificar los intereses de todas las partes permite encontrar soluciones que beneficien",
          "Identificar los intereses de ambas partes Identificar los intereses de todas las partes permite encontrar soluciones que beneficien ",
          "Establecer un ultimátum Identificar los intereses de todas las partes permite encontrar soluciones que beneficien ",
          "Mantener una posición inflexible Identificar los intereses de todas las partes permite encontrar soluciones que beneficien ",
        ],
        correctAnswer: [{ id: "1", text: "Hacer una oferta agresiva  Identificar los intereses de todas las partes permite encontrar soluciones que beneficien" }],
        selectedAnswer: [],
        explanation:
          "Identificar los intereses de todas las partes permite encontrar soluciones que beneficien a todos.",
      },
      {
        id: "2",
        type: "single-choice",
        question:
          "Una negociación exitosa siempre resulta en que una parte gana y la otra pierde.",
        options: ["Verdadero", "Falso"],
        correctAnswer: [{
          id: "2",
          text: "Verdadero",
        }],
        selectedAnswer: [],
        explanation:
          "Las mejores negociaciones resultan en acuerdos donde ambas partes ganan (win-win).",
      },
      {
        id: "3",
        type: "multiple-choice",
        question: "¿Qué significa BATNA en una negociación?",
        options: [
          "Better Alternative To Negotiated Agreement",
          "Basic Agreement Terms and Negotiation Aspects",
          "Business Approach To Negotiation Analysis",
          "Best Attitude Towards Negotiation Arrangement",
        ],
        correctAnswer: [{ id: "3", text: "Business Approach To Negotiation Analysis" }],
        selectedAnswer: [],
        explanation:
          "BATNA representa la mejor alternativa disponible si la negociación no tiene éxito.",
      },
      {
        id: "3",
        type: "matching-choice",
        question: "¿Qué significa BATNA en una negociación?",
        options: [
          "Dog",
          "Cat",
          "Bird",
        ],
        pairedWord: ["Bark", "Meow", "Tweet"],
        correctAnswer: [{ id: "1", text: "Better Alternative To Negotiated Agreement" }],
        selectedAnswer: [],
        explanation:
          "BATNA representa la mejor alternativa disponible si la negociación no tiene éxito.",
      },
    ],
  },
};

export const getQuizById = (quizId: string): Quiz | undefined => {
  return quizzes[quizId];
};

export const getNextContent = (
  courseId: string,
  currentQuizId: string
): string | undefined => {
  const course = courses[courseId];
  if (!course) return undefined;

  const currentIndex = course.playlist.findIndex(
    (item) => item.id === currentQuizId
  );
  if (currentIndex === -1 || currentIndex === course.playlist.length - 1)
    return undefined;

  return course.playlist[currentIndex + 1].id;
};
