import {
  CheckCircle,
  XCircle,
  AlertTriangle,
  SortAsc,
  SortDesc,
} from "lucide-react";

interface mockStudentsTypes {
  id: string;
  name: string;
  email: string;
  course: string;
  assessments: {
    id: string;
    title: string;
    score: number;
    status: string;
    submittedAt: string | null;
  }[];
}

interface GradebookTableProps {
  students: mockStudentsTypes[];
  onUpdateGrade: (
    studentId: string,
    assessmentId: string,
    score: number
  ) => void;
  sortConfig: {
    key: string;
    direction: "asc" | "desc";
  };
  onSort: (config: any) => void;
}

export default function GradebookTable({
  students,
  onUpdateGrade,
  sortConfig,
  onSort,
}: GradebookTableProps) {
  const handleSort = (key: string) => {
    onSort({
      key,
      direction:
        sortConfig.key === key && sortConfig.direction === "asc"
          ? "desc"
          : "asc",
    });
  };

  const renderSortIcon = (key: string) => {
    if (sortConfig.key !== key) return null;
    return sortConfig.direction === "asc" ? (
      <SortAsc className="h-4 w-4" />
    ) : (
      <SortDesc className="h-4 w-4" />
    );
  };

  return (
    <div className="bg-secondary-100 rounded-lg shadow-lg overflow-hidden">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-700">
          <thead>
            <tr className="bg-primary-50">
              <th
                className="px-6 py-3 text-left text-xs font-medium text-white uppercase cursor-pointer"
                onClick={() => handleSort("name")}
              >
                <div className="flex items-center space-x-1">
                  <span>Estudiante</span>
                  {renderSortIcon("name")}
                </div>
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                Curso
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                Evaluación
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                Estado
              </th>
              <th
                className="px-6 py-3 text-left text-xs font-medium text-white uppercase cursor-pointer"
                onClick={() => handleSort("score")}
              >
                <div className="flex items-center space-x-1">
                  <span>Calificación</span>
                  {renderSortIcon("score")}
                </div>
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase">
                Fecha
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700">
            {students.map((student) =>
              student.assessments.map((assessment) => (
                <tr
                  key={`${student.id}-${assessment.id}`}
                  className="hover:bg-gray-700"
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>
                      <div className="text-sm font-medium text-white">
                        {student.name}
                      </div>
                      <div className="text-sm text-white">{student.email}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                    {student.course}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                    {assessment.title}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      {assessment.status === "passed" && (
                        <CheckCircle className="h-5 w-5 text-green-500 mr-2" />
                      )}
                      {assessment.status === "failed" && (
                        <XCircle className="h-5 w-5 text-red-500 mr-2" />
                      )}
                      {assessment.status === "pending" && (
                        <AlertTriangle className="h-5 w-5 text-yellow-500 mr-2" />
                      )}
                      <span className="text-sm text-gray-300">
                        {assessment.status.charAt(0).toUpperCase() +
                          assessment.status.slice(1)}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <input
                      type="number"
                      min="0"
                      max="100"
                      value={assessment.score}
                      onChange={(e) =>
                        onUpdateGrade(
                          student.id,
                          assessment.id,
                          parseInt(e.target.value)
                        )
                      }
                      className="w-20 bg-gray-700 border border-gray-600 rounded-md px-2 py-1 text-white"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                    {assessment.submittedAt || "-"}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
