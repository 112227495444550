import { Play, SquareCheckBig } from "lucide-react";
import { FC } from "react";
import { useNavigation } from "../../../../hooks";

interface ActivityCardProps {
  title: string;
  description: string;
  accessmentType: string;
  time: string;
}

export const ActivityCard: FC<ActivityCardProps> = ({
  description,
  title,
  time,
  accessmentType,
}) => {

  return (
    <div className="flex gap-4 w-full py-4 ">
      {accessmentType === "assessments" ? (
        <SquareCheckBig color="#E30613" width={30} height={30} />
      ) : (
        <Play color="#E30613" width={30} height={30} />
      )}
      <div>
        <p className="text-gray-300 font-light">
          {description}{" "}
          <span className="text-white font-semibold">{title}</span>{" "}
          {accessmentType === "assessments" ? "assessment" : ""}
        </p>
        <span className="text-gray-400 font-medium text-sm">{time}</span>
      </div>
    </div>
  );
};
