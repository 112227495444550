import { FC, PropsWithChildren } from "react";
import { Button, Modal } from "../../../../components";
import dayjs from "dayjs";
import { LearniningPathSessionEntity } from "../../../../../data/models/learningPathSessionEntity.model";
interface ConfirmModalSessionProps {
  showModal: boolean;
  onClose: () => void;
  isRegistered: boolean;
  learningPathSession: LearniningPathSessionEntity;
  handleRegisterForLearningPathSession: () => void;
  handleUnRegisterForLearningPathSession: () => void;
}
const ConfirmModalSession: FC<PropsWithChildren<ConfirmModalSessionProps>> = ({
  showModal,
  onClose,
  isRegistered,
  learningPathSession,
  handleRegisterForLearningPathSession,
  handleUnRegisterForLearningPathSession,
}) => {
  return (
    <Modal showModal={showModal} onClose={onClose}>
      <div className="lg:min-w-[600px]">
        <div>
          <div className="flex items-center justify-between">
            <h3 className="text-primary-100 text-4xl font-bold leading-tight flex-1 text-center ">
              {!isRegistered
                ? "Confirmar registro de sesión"
                : "¡Cancelar el registro de la sesión!"}
            </h3>
          </div>
          <div className="grid  gap-3 p-4">
            <div className="flex flex-col gap-3 text-center pb-3">
              <div className="px-4"></div>
              <div>
                <p className=" text-3xl font-bold leading-normal">
                  Sesión en vivo: {learningPathSession.name}
                </p>
              </div>
            </div>
          </div>
          <h3 className=" text-lg font-bold leading-tight tracking-[-0.015em] px-4 pb-2 pt-4">
            Date &amp; Time
          </h3>
          <div className="flex items-center gap-4 bg-slate-50 px-4 min-h-[72px] py-2">
            <div
              className=" flex items-center justify-center rounded-lg bg-[#e7edf3] shrink-0 size-12"
              data-icon="Calendar"
              data-size="24px"
              data-weight="regular"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24px"
                height="24px"
                fill="currentColor"
                viewBox="0 0 256 256"
              >
                <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-96-88v64a8,8,0,0,1-16,0V132.94l-4.42,2.22a8,8,0,0,1-7.16-14.32l16-8A8,8,0,0,1,112,120Zm59.16,30.45L152,176h16a8,8,0,0,1,0,16H136a8,8,0,0,1-6.4-12.8l28.78-38.37A8,8,0,1,0,145.07,132a8,8,0,1,1-13.85-8A24,24,0,0,1,176,136,23.76,23.76,0,0,1,171.16,150.45Z"></path>
              </svg>
            </div>
            <div className="flex flex-col justify-center">
              <p className=" text-base font-medium leading-normal line-clamp-1">
                {/* Thu, Feb 23, 2023 */}
                {dayjs(learningPathSession.date).format("dddd")},{" "}
                {dayjs(learningPathSession.date).format("DD")}{" "}
                {`${dayjs(learningPathSession.date).format("MMMM")}`.slice(
                  0,
                  3
                )}{" "}
                {dayjs(learningPathSession.date).format("YYYY")}.
              </p>
              <p className="text-primary-50 text-sm font-normal leading-normal line-clamp-2">
                {/* 11:30 PM */}
                {dayjs(learningPathSession.starts_at).format("HH:mm A")}
                {" - "}
                {dayjs(learningPathSession.ends_at).format("HH:mm A")}
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center gap-10 p-4">
          <Button color="third" onClick={onClose}>
            Cancelar
          </Button>
          {isRegistered ? (
            <Button onClick={handleUnRegisterForLearningPathSession}>
              Darse de baja
            </Button>
          ) : (
            <Button onClick={handleRegisterForLearningPathSession}>
              Registro
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModalSession;
