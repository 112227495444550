import { PanelsTopLeft, Plus, Settings } from "lucide-react";
import {
  Button,
  SelectControlled,
  TextFieldControlled,
} from "../../../../components";
import { useNewEvaluationViewModel } from "../../../../viewModels";
import { Question } from "../Question";
import { QuestionSlider } from "../QuestionSlider";
export const NewEvaluationForm = () => {
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    onSubmit,
    errors,
    getValues,
    numberOfQuestion,
    currentQuestionNumber,
    evaluationType,
    courses,

    questions,
    handleNext,
    handlePrevious,
  } = useNewEvaluationViewModel();
  return (
    <div>
      {!numberOfQuestion ? (
        <div className="bg-secondary-100 py-8 px-6 rounded-xl flex flex-col gap-6">
          <h3 className="text-white text-2xl lg:text-3xl font-bold">
            Nuevo cuestionario
          </h3>
          <div className="flex items-center gap-6">
            <TextFieldControlled
              id={"title"}
              name="title"
              label="Título"
              placeholder=""
              error={!!errors.title}
              helperText={errors?.title?.message}
              control={control}
              required
            />
            <TextFieldControlled
              id={"timeLimit"}
              name="timeLimit"
              label="Tiempo Límite (minutos)"
              placeholder="60"
              type="number"
              error={!!errors.timeLimit}
              helperText={errors?.timeLimit?.message}
              control={control}
              required
            />
          </div>
          <div className="flex items-center gap-6">
            <SelectControlled
              id={"Tipo"}
              name="tipo"
              label="Seleccionar tipo"
              placeholder="Elige tu Tipo"
              error={!!errors.tipo}
              helperText={errors?.tipo?.message}
              control={control}
              options={evaluationType}
              required
            />
            <SelectControlled
              id={"courseId"}
              name="courseId"
              label="Seleccionar curso"
              placeholder="60"
              error={!!errors.courseId}
              helperText={errors?.courseId?.message}
              control={control}
              options={courses}
              required
            />
          </div>
          <div className="flex items-center gap-6">
            <div className="w-full">
              <TextFieldControlled
                id={"numberOfQuestion"}
                name="numberOfQuestion"
                label="Número de preguntas"
                placeholder="60"
                type="number"
                error={!!errors.timeLimit}
                helperText={errors?.timeLimit?.message}
                control={control}
                required
              />
            </div>
            {/* <Button
              color={
                getValues("questionPageType") === "onePerPage"
                  ? "primary"
                  : "third"
              }
              onClick={() => {
                setValue("date", "onePerPage");
              }}
            >
              <span className="flex items-center gap-4">
                <PanelsTopLeft color="#fff" /> Una por página
              </span>
            </Button>
            <Button
              onClick={() => {
                setValue("date", "allQuestion");
              }}
              color={
                getValues("questionPageType") === "allQuestion"
                  ? "third"
                  : "primary"
              }
            >
              <span className="flex items-center gap-4">
                <Settings color="#fff" />
                Todas las preguntas
              </span>
            </Button> */}
          </div>
          <Button onClick={handleSubmit(onSubmit)} className="w-full">
            Guardar Evaluación
          </Button>
        </div>
      ) : (
        <div>
          <Question
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            currentQuestionNumber={currentQuestionNumber}
            numberOfQuestion={numberOfQuestion}
          />
        </div>
      )}
    </div>
  );
};
