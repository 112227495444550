import { SquareCheckBig } from "lucide-react";
import { FC, useEffect, useState } from "react";
import { CheckBox } from "../../../../components";

interface selectedOptionCheck {
  id: string;
  text: string;
}
interface MultipleSelectionProps {
  id: string;
  questionType: "multiple-choice" | "single-choice" | "matching-choice";
  question: string;
  options: string[];
  onAnswer: (
    questionId: string,
    questionType: "multiple-choice" | "single-choice" | "matching-choice",
    answer: string
  ) => void;
  selectedAnswer: selectedOptionCheck[];
}

export const MultipleSelection: FC<MultipleSelectionProps> = ({
  id,
  questionType,
  question,
  onAnswer,
  options,
  selectedAnswer,
}) => {
  const answerCheck = (option: string, selected: selectedOptionCheck[]) => {
    const correct =
      selectedAnswer.filter((selected) => selected.text === option).length > 0
        ? true
        : false;
    return correct;
  };
  return (
    <div className="mx-auto bg-black p-8 rounded-lg shadow-md w-full">
      <div className="space-y-8">
        <div>
          <h3 className="text-2xl font-medium mb-6">{question}</h3>
          <div className="space-y-3">
            {options.map((option) => (
              <button
                onClick={() => {
                  onAnswer(id, questionType, option);
                }}
                key={option}
                className={`flex items-center p-3 rounded-lg cursor-pointer transition-colors w-full
                    ${
                      answerCheck(option, selectedAnswer)
                        ? "bg-primary-50 "
                        : "bg-secondary-300 hover:bg-secondary-100 "
                    }
                   `}
              >
                {answerCheck(option, selectedAnswer) ? (
                  <SquareCheckBig className="mr-4" />
                ) : (
                  <CheckBox
                    className={""}
                    checked={
                      selectedAnswer.filter(
                        (selected) => selected.text === option
                      ).length > 0
                        ? true
                        : false
                    }
                  />
                )}
                <span className="ml-3 text-white text-left">{option}</span>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
