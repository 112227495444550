import { GetLearningPathsSession, LearningPathSessions, RegisterLearningPathsSessions, UnRegisterLearningPathsSession } from "../../services/learningPathsSession.service";
import { handleResponse } from "../../shared";

export function useCaseLearningPathSessions(service: LearningPathSessions) {
    return {
        getListLearningPathsSessions({ success, error, learningPathUuid }: GetLearningPathsSession) {
            return service.getListLearningPathsSessions({ success, error, learningPathUuid });
        },
        postResgisterLearningPathsSessions({ success, error, learningPathUuid }: RegisterLearningPathsSessions) {
            return service.postResgisterLearningPathsSessions({ success, error, learningPathUuid });
        },
        postUnRegisterLearningPathsSessions({ success, error, learningPathUuid }: UnRegisterLearningPathsSession) {
            return service.postUnRegisterLearningPathsSessions({ success, error, learningPathUuid });
        }
    }
}