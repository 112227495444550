import { useForm, FieldValues } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { LearningPathEntity, selectOptionEntity } from "../../../domain/entities";
import { AxiosError } from "axios";
import { useCustomEffect, useNavigation } from "../../hooks";
import { useCaseLearningPath } from "../../../domain/useCases";
import { learningPathDataService } from "../../../data/services/learningPath.data.service";
import { sessionSchema } from "../../validators/sessionSchema";
import { learninPathSessionDataService } from "../../../data/services/createSession.service";
import { CreateSessionData } from "../../../domain/services/createSession.service";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { LearningPathSessionDetailsModel } from "../../../data/models/learningPathSessionModel";


export const CreateSessionViewModel = () => {
  const timeOptions: Array<selectOptionEntity> = [
    { id: "00:00", name: "00:00" },
    { id: "01:00", name: "01:00" },
    { id: "02:00", name: "02:00" },
    { id: "03:00", name: "03:00" },
    { id: "04:00", name: "04:00" },
    { id: "05:00", name: "05:00" },
    { id: "06:00", name: "06:00" },
    { id: "07:00", name: "07:00" },
    { id: "08:00", name: "08:00" },
    { id: "09:00", name: "09:00" },
    { id: "10:00", name: "10:00" },
    { id: "11:00", name: "11:00" },
    { id: "12:00", name: "12:00" },
    { id: "13:00", name: "13:00" },
    { id: "14:00", name: "14:00" },
    { id: "15:00", name: "15:00" },
    { id: "16:00", name: "16:00" },
    { id: "17:00", name: "17:00" },
    { id: "18:00", name: "18:00" },
    { id: "19:00", name: "19:00" },
    { id: "20:00", name: "20:00" },
    { id: "21:00", name: "21:00" },
    { id: "22:00", name: "22:00" },
    { id: "23:00", name: "23:00" },
  ];
  const statusOptions: Array<selectOptionEntity> = [
    {
      id: "1",
      name: "Cancelada",
      label: "cancelled",
    },
    {
      id: "2",
      name: "En curso",
      label: "in_progress",
    },
    {
      id: "3",
      name: "Confirmada",
      label: "confirmed",
    },
  ];
  const { navigateTo, getQueryParam } = useNavigation();
  const sessionId = getQueryParam("uuid");
  const { createSession, getSessionDetails, updateSession } =
    learninPathSessionDataService();
  const [listLearningPathsToRent, setlistLearningPathsToRent] = useState<
    Array<LearningPathEntity>
  >([]);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(sessionSchema) });

  const { getlistLearningPathsToRentToRent } = useCaseLearningPath(
    learningPathDataService()
  );

  const createSessionSuccess = (data: CreateSessionData) => {
    toast.success("Sesión creada para la ruta de aprendizaje exitosamente");
    navigateTo("/confirm-session");
  };
  const createSessionError = (error: AxiosError) => {
    toast.error(error.message);
  };
  const onSubmit = (data: FieldValues) => {
    const learningPathName = listLearningPathsToRent.filter(
      (item) => item.uuid === data.learningPath
    )[0].title;
    const status = statusOptions.filter((item) => item.id === data.status)[0]
      .label;
    const date = dayjs(data.date).format("YYYY-MM-DD");
    const startAt = `${date} ${data.startAt}:00`;
    const endAt = `${date} ${data.endAt}:00`;
    if (sessionId) {
      updateSession({
        body: {
          learningPathId: data.learningPath,
          name: learningPathName,
          startAt: startAt,
          endAt: endAt,
          status: status ? status : "",
          date: date,
        },
        success: createSessionSuccess,
        error: createSessionError,
        learningPathSessionId: Number(sessionId),
      });
    } else {
      createSession({
        body: {
          learningPathId: data.learningPath,
          name: learningPathName,
          startAt: startAt,
          endAt: endAt,
          status: status ? status : "",
          date: date,
        },
        success: createSessionSuccess,
        error: createSessionError,
      });
    }
  };

  const closeSussionSuccessModal = () => {
    setShowSuccessModal(false);
  };

  function successGetListOfLearningPathsToRent(
    learningPaths: Array<LearningPathEntity>
  ) {
    setlistLearningPathsToRent(learningPaths);
  }

  function errorGetListOfLearningPathsToRent(e: AxiosError) {
    console.log("errorGetListOfLearningPathsToRent", e);
  }

  const cancelSession = () => {
    const learningPathName = listLearningPathsToRent.filter(
      (item) => item.uuid === getValues("learningPath")
    )[0].title;
    const status = statusOptions.filter((item) => item.id === "1")[0].label;
    const date = dayjs(getValues("date")).format("YYYY-MM-DD");
    const startAt = `${date} ${getValues("startAt")}:00`;
    const endAt = `${date} ${getValues("endAt")}:00`;
    updateSession({
      body: {
        learningPathId: getValues("learningPath"),
        name: learningPathName,
        startAt: startAt,
        endAt: endAt,
        status: status ? status : "",
        date: date,
      },
      success: createSessionSuccess,
      error: createSessionError,
      learningPathSessionId: Number(sessionId),
    });
  };

  useCustomEffect(() => {
    // set date default value
    getlistLearningPathsToRentToRent({
      success: successGetListOfLearningPathsToRent,
      error: errorGetListOfLearningPathsToRent,
    });

    const successGetLearningPathSession = (
      data: LearningPathSessionDetailsModel
    ) => {
      setValue("date", data.date ? dayjs(data.date) : dayjs());
      setValue(
        "learningPath",
        data.learningPaths ? data.learning_path_uuid : ""
      );
      setValue(
        "startAt",
        dayjs(data.starts_at).format("HH:mm")
          ? dayjs(data.starts_at).format("HH:mm")
          : ""
      );
      setValue(
        "endAt",
        dayjs(data.ends_at).format("HH:mm")
          ? dayjs(data.ends_at).format("HH:mm")
          : ""
      );
      setValue("status", "3");
    };
    const errorGetLearningPathSession = () => { };

    if (sessionId) {
      getSessionDetails({
        success: successGetLearningPathSession,
        error: errorGetLearningPathSession,
        learningPathSessionId: Number(sessionId),
      });
    } else {
      setValue("date", dayjs().add(1, "day"));
    }
  }, []);

  return {
    handleSubmit,
    control,
    setValue,
    setError,
    onSubmit,
    errors,
    getValues,
    sessionId,

    cancelSession,
    showSuccessModal,
    closeSussionSuccessModal,
    timeOptions,
    statusOptions,
    listLearningPathsToRent,
  };
};
