import { FC } from "react";
import { StyledFavoriteCard, StyledFavorites } from "./WeeklyFavorites.styles";

import { CategoryEntity } from "../../../../../domain/entities";
import { Button, Carousel } from "../../../../components";

export interface WeeklyFavoritesProps {
  favoritesProductions: [] | CategoryEntity[];
  navigateTo: (
    url: string,
    state?:
      | {
          [key: string]: any;
        }
      | undefined
  ) => void;
}

export const WeeklyFavorites: FC<WeeklyFavoritesProps> = ({
  favoritesProductions,
  navigateTo,
}) => {
  return (
    <StyledFavorites>
      <div className=" w-full lg:px-10 px-0">
        <h2>LOS FAVORITOS DE LA SEMANA</h2>
        <Carousel
          slidesToShow={1}
          slidesToScroll={1}
          infinite={true}
          dots={false}
          arrows={true}
          responsive={[
            {
              breakpoint: 1380,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
                arrows: true,
              },
            },
            {
              breakpoint: 1180,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                arrows: false,
                centerMode: true,
                centerPadding: "68px",
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                arrows: false,
                centerMode: true,
                centerPadding: "68px",
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
                arrows: false,
                centerMode: true,
                centerPadding: "68px",
              },
            },
          ]}
        >
          {favoritesProductions[0]?.productions &&
            favoritesProductions[0]?.productions?.map((production, index) => {
              return (
                <StyledFavoriteCard
                  key={`f-${index}`}
                  productionData={production}
                  withPlayButton={true}
                />
              );
            })}
        </Carousel>
      </div>

      <div className="flex justify-center lg:hidden">
        <Button onClick={() => navigateTo("/catalog")} className="mb-16">
          Ver todo el catálogo
        </Button>
      </div>
    </StyledFavorites>
  );
};
