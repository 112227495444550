import { DefaultLayout } from "../../layouts";
import { AiQuestionGenerator, NewEvaluationForm } from "./components";

export const CreateQuizPage = () => {
  return (
    <DefaultLayout>
      <div className="px-8 lg:px-14 flex flex-col md:flex-row gap-6 mb-6 max-w-4xl mx-auto">
        <div className="w-full ">
          <NewEvaluationForm />
        </div>
        {/* <div className="w-full"><AiQuestionGenerator /></div> */}
      </div>
    </DefaultLayout>
  );
};
