import { FC } from "react";
import { DefaultLayout } from "../../layouts";
import { useHomeViewModel } from "../../viewModels";
import {
  Button,
  Carousel,
  CategoryCarousel,
  LearningPathCard,
} from "../../components";
import {
  Banner,
  GoSubscribe,
  Quote,
  Subscriptions,
  Podcast,
  GameSection,
} from "./components";
import { StyledHomeContainer, StyledSection } from "./home.styles";
import { LearningPathBanner } from "./components/LearningPathBanner";
import AvailableLearningPaths from "./components/AvailableLearningPaths/AvailableLearningPaths";
import { StyledLearningPathsSection } from "../learningPathsLanding/learningPathsLanding.styles";
import { CourseSection } from "../studentAcessmentDashboard/components";
export const Home: FC = () => {
  const {
    userSubscription,
    token,
    homeData,
    favoritesProductions,

    productions,
    podcastProductions,
    gameProductions,

    subscriptionsList,
    handleCallToActionCard,
    navigateTo,
    listLearningPathsToRent,
    onGetGameById,
  } = useHomeViewModel();

  return (
    <DefaultLayout isAbsolute>
      <StyledHomeContainer>
        <Banner
          title={homeData?.banner?.title}
          description={homeData?.banner?.description}
          video={homeData?.banner?.video}
          production={homeData?.banner?.production}
          subscribed={userSubscription?.stripeStatus}
        />
        <StyledSection withoutPadding className="StyledSection">
          {favoritesProductions.map((production, index) => {
            const {
              title,
              productions: productionList,
              description,
            } = production;
            return (
              <CategoryCarousel
                key={`F-${index}`}
                categoryName={title}
                categoryDescription={description}
                productions={productionList}
                currentCardPaddingMobile="72px"
              />
            );
          })}
        </StyledSection>

        {!token && (
          <StyledSection className="StyledSection">
            <h2>
              {homeData?.suscribe?.section?.title?.value
                ? homeData.suscribe.section.title.value
                : "SUSCRÍBETE Y OBTÉN UNA PRUEBA GRATIS"}
            </h2>
            <GoSubscribe />
          </StyledSection>
        )}
        <div>
          <h2 className="font-primaryBold text-xl my-8 text-white text-center lg:text-3xl">
            CURSOS DISPONIBLE
          </h2>
          <div className="w-full lg:px-28 px-0 ">
            <CourseSection />
          </div>
        </div>
        <StyledSection withoutPadding className="StyledSection">
          <article className="flex flex-col items-center gap-y-3 ">
            <h2>
              <span>
                {homeData?.catalog?.title?.value
                  ? homeData.catalog.title.value
                  : "NUESTRAS PRODUCCIONES"}
              </span>
            </h2>
            <p className="hidden lg:block">
              {homeData?.catalog?.description?.value
                ? homeData.catalog.description.value
                : 'Todas nuestras producciones son originales y han sido realizadas por el equipo de "storytraining" utilizando su gran experiencia práctica y pedagógica en cada una de nuestras Áreas Temáticas. Contodas ellas aprenderás mientras te diviertes. ¡No te lo pierdas!'}
            </p>
          </article>
          {productions.map((production, index) => {
            const {
              title,
              productions: productionList,
              description,
            } = production;
            return (
              <CategoryCarousel
                className="mt-8 lg:mt-16"
                key={`P-${index}`}
                categoryName={title}
                categoryDescription={description}
                productions={productionList}
                hideCategory={true}
                currentCardPaddingMobile="72px"
              />
            );
          })}

          <h3 className="mt-8 lg:mt-16">
            CONOCE <span>TODAS</span> NUESTRAS <span>ÁREAS TEMÁTICAS</span>
          </h3>
          <Button
            onClick={() => (window.location.href = "/catalog")}
            className="mt-6"
          >
            Ver todo el catálogo
          </Button>
        </StyledSection>
        {podcastProductions?.productions.length && (
          <StyledSection withoutPadding={true} className="StyledSection">
            <Podcast podcastProductions={podcastProductions} />
          </StyledSection>
        )}
        {gameProductions?.games.length && (
          <StyledSection withoutPadding={true} className="StyledSection">
            <GameSection
              gameProductions={gameProductions}
              onClickCard={onGetGameById}
            />
          </StyledSection>
        )}

        {/* <StyledSection>
          <StyledContainerCarousel>
            <Carousel
              slidesToShow={1}
              slidesToScroll={1}
              infinite={false}
              dots={false}
              responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                    centerMode: true,
                    centerPadding: "45px",
                  },
                },
              ]}
            >
              {quotes?.map((item, index) => {
                return (
                  <Quote
                    key={index}
                    quote={item.quote}
                    quoteBy={item.quoteBy}
                    position={item.position}
                    course={item.course}
                  />
                );
               })}
            </Carousel>
          </StyledContainerCarousel>
        </StyledSection> */}

        {/* Course section starts */}

        {/* Course section ends */}
        {/* Learning paths section starts */}
        {/* <LearningPathBanner navigateTo={navigateTo} /> */}
        <StyledLearningPathsSection>
          <h2 className="font-primaryBold text-xl my-5 text-white text-center lg:text-4xl">
            CAMINOS DE APRENDIZAJE DISPONIBLES
          </h2>
          <div className="mx-auto w-full lg:px-44 px-0 max-w-dashboard">
            <Carousel
              slidesToShow={listLearningPathsToRent.length == 1 ? 1 : 2}
              slidesToScroll={1}
              infinite={false}
              dots={false}
              responsive={[
                {
                  breakpoint: 1120,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                    centerMode: true,
                    centerPadding: "65px",
                  },
                },
              ]}
            >
              {listLearningPathsToRent.map((learningPath: any) => {
                return (
                  <LearningPathCard
                    key={learningPath.uuid}
                    className={"lg:px-4"}
                    learningPathData={{
                      uuid: learningPath.uuid,
                      title: learningPath.title,
                      imgPreview: learningPath.image,
                      // description: "",
                    }}
                  />
                );
              })}
            </Carousel>
          </div>
        </StyledLearningPathsSection>
        {/* Learning paths section ends */}

        {(userSubscription?.stripeStatus !== "active" || !token) && (
          <StyledSection>
            <Subscriptions
              token={token}
              subscriptionsList={subscriptionsList}
              handleCallToActionCard={handleCallToActionCard}
              homeData={homeData}
            />
          </StyledSection>
        )}
      </StyledHomeContainer>
      {/* {!!token && <StyledSection className="StyledSection" />} */}
    </DefaultLayout>
  );
};
