import { Button } from "../../../../components";
import { Status } from "../../../../components/Status";
import { FC, PropsWithChildren } from "react";
import { LearniningPathSessionEntity } from "../../../../../data/models/learningPathSessionEntity.model";
import dayjs from "dayjs";

interface SessionCardProps {
  handleOpenModal: (id: number) => void;
  learningPathSession: LearniningPathSessionEntity;
}
const SessionCard: FC<PropsWithChildren<SessionCardProps>> = ({
  handleOpenModal,
  learningPathSession,
}) => {
  return (
    <div className="p-4 relative cursor-pointer max-w-md">
      <div className="flex flex-col items-stretch justify-between gap-4 rounded-xl bg-white pt-8 pb-4">
        <div className="w-full bg-center  rounded-xl flex-1"></div>
        <div className="flex  flex-col gap-4">
          <div className="flex flex-col gap-1">
            <p className=" font-bold leading-tight text-3xl text-center lg:text-left lg:px-4">
              Sesión en vivo: {learningPathSession.name}
            </p>
            <p className="text-primary-50 text-base font-normal leading-normal  text-center lg:text-left lg:px-4">
              {`${dayjs(learningPathSession.date).format("dddd")}`.slice(0, 3)},{" "}
              {`${dayjs(learningPathSession.date).format("DD")}`.slice(0, 3)}{" "}
              {`${dayjs(learningPathSession.date).format("MMMM")}`.slice(0, 3)}{" "}
              {dayjs(learningPathSession.date).format("YYYY")},{" "}
              {dayjs(learningPathSession.starts_at).format("HH:mm A")}
              {" - "}
              {dayjs(learningPathSession.ends_at).format("HH:mm A")}.
            </p>
          </div>
          <div>
            <Status
              text={
                !learningPathSession.isRegistered ? "Confirmada" : "Registrada"
              }
            />
          </div>
          <div className="flex items-center justify-center">
            {learningPathSession.isRegistered ? (
              <Button
                className="w-[200px]"
                onClick={() => {
                  handleOpenModal(learningPathSession.id);
                }}
              >
                Darse de baja
              </Button>
            ) : (
              <Button
                className="w-[200px]"
                onClick={() => {
                  handleOpenModal(learningPathSession.id);
                }}
              >
                Registrar sesión
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionCard;
