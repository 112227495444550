import { BookOpen, Users, SquareCheckBig, Clock4 } from "lucide-react";
import { Button } from "../../components";
import CountCard from "../../components/CountCard/CountCard";
import { DefaultLayout } from "../../layouts";
import { ActivityCard } from "./components";
import { EvaluationCard } from "./components/EvaluationCard";
import { useNavigation } from "../../hooks";

export const TeacherDashboard = () => {
  const { navigateTo } = useNavigation();
  const dashBoardData: Array<{ title: string; icon: any; count: string }> = [
    {
      title: "Estudiantes Activos",
      icon: <Users color="#E30613" width={30} height={30} />,
      count: "4",
    },
    {
      title: "Evaluaciones Creadas",
      icon: <SquareCheckBig color="#E30613" width={30} height={30} />,
      count: "45",
    },
    {
      title: "Banco de Preguntas",
      icon: <BookOpen color="#E30613" width={30} height={30} />,
      count: "48",
    },
    {
      title: "Tiempo Promedio",
      icon: <Clock4 color="#E30613" width={30} height={30} />,
      count: "48 min",
    },
  ];

  const recentActivities: Array<{
    description: string;
    title: string;
    time: string;
    accessmentType: string;
  }> = [
    {
      description: "Completaste la evaluación",
      title: `Nueva evaluación creada`,
      time: "Hace 2 horas",
      accessmentType: "assessments",
    },
    {
      description: "Viste el video ",
      title: `Calificaciones actualizadas`,
      time: "Hace 2 horas",
      accessmentType: "course",
    },
  ];

  const evaluationData: Array<{
    description: string;
    title: string;
    date: string;
    status: "Activa" | "Borrador";
    completed: string;
    total: string;
  }> = [
    {
      description: "El Ascensor",
      title: `Liderazgo Efectivo`,
      date: "2024-03-20",
      status: "Activa",
      completed: "12",
      total: "20",
    },
    {
      description: "La Mejor Alternativa",
      title: `Conceptos de Negociación`,
      date: "2024-03-25",
      status: "Borrador",
      completed: "12",
      total: "20",
    },
  ];
  return (
    <DefaultLayout>
      <div className="mx-4 lg:mx-16 ">
        <div className="flex justify-between items-center mb-8 ">
          <h2 className="text-lg lg:text-4xl text-white font-bold ">
            Dashboard del Profesor
          </h2>
          <div className="flex items-center gap-4">
            <Button
              onClick={() => {
                navigateTo("/teacher/assessment/create");
              }}
            >
              Nueva Evaluación
            </Button>
            <Button
              onClick={() => {
                navigateTo("/teacher/gradebook");
              }}
              color="secondary"
            >
              Calificaciones
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 ">
          {dashBoardData.map((item) => (
            <CountCard {...item} />
          ))}
        </div>
      </div>
      <div className="block lg:flex gap-8 mx-4 lg:mx-16 my-10">
        <div className="bg-secondary-100   rounded-md w-full">
          <h2 className="text-lg lg:text-3xl text-white font-bold py-6 border-b border-solid border-gray-400 px-6">
            Evaluaciones Recientes
          </h2>
          <div className="p-6 flex flex-col gap-6">
            {evaluationData.map((evaluation, index: number) => (
              <EvaluationCard {...evaluation} key={index} />
            ))}
          </div>
        </div>
        {/* Recent activity session */}
        <div className="bg-secondary-100 rounded-md w-full">
          <h2 className="text-lg lg:text-3xl text-white font-bold py-6 border-b border-solid border-gray-400 px-6">
            Actividad Reciente
          </h2>
          <div className="p-6">
            {recentActivities.map((recentActivity, index: number) => (
              <ActivityCard {...recentActivity} key={index} />
            ))}
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};
