import { useState, useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";
import { Clock } from "lucide-react";
import {
  QuizProgress,
  QuizResults,
  MatchingWordComponent,
  MultipleSelection,
  QuizQuestion,
} from "./components";

import { getQuizById, getNextContent, QuizQuestionInterface } from "./data";
import { DefaultLayout } from "../../layouts";
import { Button } from "../../components";

export function QuizPage() {
  const { courseId, quizId } = useParams();
  const quiz = getQuizById(quizId ? quizId : "");

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [corectAnswers, setCorrectAnswers] = useState<number>(0);
  const [incorrectAnswers, setInCorrectAnswers] = useState<number>(0);
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [quizQuestion, setQuizQuestion] = useState<QuizQuestionInterface[]>(
    quiz?.questions ? quiz.questions : []
  );

  if (!courseId || !quizId) {
    return <Navigate to="/student/dashboard" />;
  }

  if (!quiz) {
    return <Navigate to={`/course/${courseId}`} />;
  }

  // useEffect(() => {
  //   setTimeLeft(quiz.timeLimit * 60);
  //   const timer = setInterval(() => {
  //     setTimeLeft((prev) => {
  //       if (prev <= 1) {
  //         clearInterval(timer);
  //         handleFinish();
  //         return 0;
  //       }
  //       return prev - 1;
  //     });
  //   }, 1000);

  //   return () => clearInterval(timer);
  // }, [quiz.timeLimit]);

  const handleFinish = () => {
    setShowResults(true);
  };

  // Function to compare objects
  const areObjectsEqual = (obj1: any, obj2: any) => {
    return Object.keys(obj1).every((key) => obj1[key] === obj2[key]);
  };

  // Function to count match objects
  const countMatchingObjects = (arr1: any, arr2: any) => {
    let count = 0;

    arr1.forEach((obj1: any) => {
      if (arr2.some((obj2: any) => areObjectsEqual(obj1, obj2))) {
        count++;
      }
    });

    return count;
  };

  const onAnswer = (
    questionId: string,
    questionType: "multiple-choice" | "single-choice" | "matching-choice",
    answer: string
  ) => {
    let questionsUpdate: QuizQuestionInterface[] = [];
    if (questionType === "multiple-choice") {
      questionsUpdate = quizQuestion.map((question) => {
        let multipleSelection: { text: string; id: string }[] = [];
        if (question.id === questionId) {
          if (
            question.selectedAnswer.filter(
              (selected) =>
                selected.id === questionId && selected.text === answer
            ).length > 0
          ) {
            // Remove previously selected option
            multipleSelection = question.selectedAnswer.filter(
              (selected) => selected.text !== answer
            );
          } else {
            multipleSelection = [
              ...question.selectedAnswer,
              { text: answer, id: questionId },
            ];
          }
          const matchingCount = countMatchingObjects(
            question.correctAnswer,
            multipleSelection
          );
          return {
            id: question.id,
            question: question.question,
            type: question.type,
            options: question.options,
            correctAnswer: question.correctAnswer,
            selectedAnswer: multipleSelection,
          };
        } else {
          return question;
        }
      });
    } else if (questionType === "single-choice") {
      questionsUpdate = quizQuestion.map((question) => {
        if (question.id === questionId) {
          const matchingCount = countMatchingObjects(question.correctAnswer, [
            { text: answer, id: questionId },
          ]);
          return {
            explanation: "",
            question: question.question,
            id: question.id,
            type: question.type,
            options: question.options,
            correctAnswer: question.correctAnswer,
            selectedAnswer: [{ text: answer, id: question.id }],
          };
        } else {
          return question;
        }
      });
    } else {
    }
    setQuizQuestion(questionsUpdate);
  };

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <DefaultLayout>
      <div className="min-h-screen text-white">
        {showResults ? (
          <div className="min-h-screen bg-black text-white py-8">
            <div className="max-w-4xl mx-auto px-4">
              <QuizResults
                score={Math.round(score)}
                totalQuestions={quiz.questions.length}
                // correctAnswers={correctAnswers}
                // incorrectAnswers={quiz.questions.length - correctAnswers}
                // nextContentId={nextContentId}
                courseId={courseId}
              />
            </div>
          </div>
        ) : (
          <div className="max-w-4xl mx-auto px-4 py-8">
            <div className="flex justify-between items-center mb-8">
              <h1 className="text-3xl font-bold">{quiz.title}</h1>
              <div className="flex items-center space-x-2 text-red-500">
                <Clock className="h-5 w-5" />
                <span>
                  {minutes.toString().padStart(2, "0")}:
                  {seconds.toString().padStart(2, "0")}
                </span>
              </div>
            </div>
            <div className="mt-8 flex flex-col gap-8 w-full">
              {quizQuestion[currentQuestion].type === "single-choice" ? (
                <QuizQuestion
                  id={quizQuestion[currentQuestion].id}
                  questionType={quizQuestion[currentQuestion].type}
                  options={quizQuestion[currentQuestion].options}
                  question={quizQuestion[currentQuestion].question}
                  onAnswer={onAnswer}
                  selectedAnswer={quizQuestion[currentQuestion].selectedAnswer}
                />
              ) : quizQuestion[currentQuestion].type === "multiple-choice" ? (
                <MultipleSelection
                  id={quizQuestion[currentQuestion].id}
                  questionType={quizQuestion[currentQuestion].type}
                  options={quizQuestion[currentQuestion].options}
                  question={quizQuestion[currentQuestion].question}
                  onAnswer={onAnswer}
                  selectedAnswer={quizQuestion[currentQuestion].selectedAnswer}
                />
              ) : (
                <MatchingWordComponent
                  id={quizQuestion[currentQuestion].id}
                  questionType={quizQuestion[currentQuestion].type}
                  options={quizQuestion[currentQuestion].options}
                  question={quizQuestion[currentQuestion].question}
                  onAnswer={onAnswer}
                  selectedAnswer={quizQuestion[currentQuestion].selectedAnswer}
                />
              )}
            </div>

            <div className="mt-8 flex w-full gap-6">
              <div className="w-full  ">
                <QuizProgress
                  total={quiz.questions.length}
                  current={currentQuestion + 1}
                  // answered={Object.keys(answers).length}
                />
              </div>
              <div className="flex gap-6 items-center">
                <Button
                  disabled={currentQuestion === 0}
                  onClick={() => {
                    setCurrentQuestion((prev) => Math.max(0, prev - 1));
                  }}
                  color="third"
                >
                  Anterior
                </Button>
                <Button
                  onClick={() => {
                    if (currentQuestion < quiz.questions.length - 1) {
                      setCurrentQuestion((prev) => prev + 1);
                    } else {
                      handleFinish();
                    }
                  }}
                  color="primary"
                >
                  {currentQuestion === quiz.questions.length - 1
                    ? "Finalizar"
                    : "Siguiente"}{" "}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </DefaultLayout>
  );
}
