import React, { FC, useState } from "react";
import { ListChecks, CircleEqual, CheckCircle2 } from "lucide-react";

type QuestionType = "matching" | "single" | "multiple" | null;
interface QuestionTypeSelectionProps {
  handleSelect: (type: string) => void;
}
export const QuestionTypeSelection: FC<QuestionTypeSelectionProps> = ({
  handleSelect,
}) => {
  const [selectedType, setSelectedType] = useState<QuestionType>(null);

  const questionTypes = [
    {
      id: "matching",
      title: "Palabras coincidentes",
      description: "Unir palabras o frases relacionadas",
      icon: CircleEqual,
    },
    {
      id: "single",
      title: "Selección única",
      description: "Elija una respuesta correcta entre múltiples opciones",
      icon: CheckCircle2,
    },
    {
      id: "multiple",
      title: "Selección múltiple",
      description: "Seleccione todas las respuestas correctas que correspondan",
      icon: ListChecks,
    },
  ];

  return (
    <div className="bg-black py-8 px-6 my-6 rounded-md">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl font-primarySemibold text-white mb-2">
          Elija el tipo de pregunta
        </h1>
        <p className="text-white mb-8">
          Seleccione el tipo de pregunta que desea crear
        </p>
        <div className="grid md:grid-cols-3 gap-6">
          {questionTypes.map((type) => {
            const Icon = type.icon;
            const isSelected = selectedType === type.id;

            return (
              <button
                key={type.id}
                onClick={() => {
                  setSelectedType(type.id as QuestionType);
                  handleSelect(type.id);
                }}
                className={`relative p-6 rounded-xl transition-all duration-200 ${
                  isSelected
                    ? "bg-primary-50 shadow-lg  scale-105"
                    : "bg-secondary-100"
                }`}
              >
                <div className="flex flex-col items-center text-center">
                  <div
                    className={`p-3 rounded-full mb-4 ${
                      isSelected ? "bg-white" : "bg-primary-50"
                    }`}
                  >
                    <Icon
                      size={24}
                      className={`${
                        isSelected ? "text-primary-50" : "text-white"
                      }`}
                    />
                  </div>
                  <h3 className="text-lg font-primarySemibold text-white mb-2">
                    {type.title}
                  </h3>
                  <p className="text-sm text-white font-primaryRegular">
                    {type.description}
                  </p>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
