import { BookOpenCheck, Play, SquareCheckBig } from "lucide-react";
import { FC } from "react";
import { useNavigation } from "../../../../hooks";

interface CourseContentCardProps {
  title: string;
  duration: number;
  accessmentType: "quiz" | "production" | "assessment";
  link: string;
}

export const CourseContentCard: FC<CourseContentCardProps> = ({
  title,
  duration,
  accessmentType,
  link,
}) => {
  const { navigateTo } = useNavigation();

  return (
    <div
      className="flex gap-4 w-full py-4 px-4 hover:bg-secondary-100 cursor-pointer bg-secondary-200 rounded-xl"
      onClick={() => {
        accessmentType === "quiz" ? navigateTo(link) : "";
      }}
    >
      <div>
        {accessmentType === "quiz" ? (
          <div className="my-3 flex items-center justify-center w-[8.5rem] h-[4rem] lg:w-[7rem] lg:h-[7rem] border-[2px] border-solid border-primary-50 rounded-md">
            <SquareCheckBig color="#E30613" width={50} height={50} />
          </div>
        ) : accessmentType === "assessment" ? (
          <div className="my-3 flex items-center justify-center w-[8.5rem] h-[4rem] lg:w-[7rem] lg:h-[7rem] border-[2px] border-solid border-primary-50 rounded-md">
            <BookOpenCheck color="#E30613" width={50} height={50} />
          </div>
        ) : (
          <div className="w-[8.5rem] h-[4rem] lg:w-[7rem] lg:h-[7rem] border-solid boreder-primary border flex items-center">
            {/* <div
              className="w-[8.5rem] h-[4rem] lg:w-[7rem] lg:h-[7rem] border-solid boreder-primary border"
              style={{
                backgroundImage: `url(https://images.unsplash.com/photo-1568992687947-868a62a9f521?auto=format&fit=crop&w=1350&q=80)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            ></div> */}
            <img
              className="w-[8.5rem] h-auto lg:w-[7rem] lg:h-auto border-solid boreder-primary border"
              src="https://images.unsplash.com/photo-1568992687947-868a62a9f521?auto=format&fit=crop&w=1350&q=80"
            />
          </div>
        )}
      </div>
      <div className="w-full border-solid boreder-primary border">
        <p className="text-gray-300 font-light">
          <h3 className="text-white text-sm lg:text-xl font-primaryBold">
            {title}
          </h3>
          <p className="text-white text-xs text-leftoverflow-hidden lg:text-base font-primaryRegular">
            {`Rendered support to all the nation for effective partnership
            Liderazgo Y Gestión De Personas`.substring(0, 38)}
            ...
          </p>
        </p>
        <span className="text-primary-100 text-xs text-left lg:text-base font-primaryRegular">
          {duration} minutes
        </span>
      </div>
    </div>
  );
};
