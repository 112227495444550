import dayjs from "dayjs";
import { Console } from "node:console";
import { start } from "node:repl";
import * as yup from "yup";

export const aiGenerationSchema = yup.object({
  theme: yup.string().required("Se requiere ruta de aprendizaje"),
  keywords: yup.string().required("Se requiere ruta de aprendizaje"),
  difficulty: yup.string().required("Se requiere ruta de aprendizaje"),
  numberOfQuestion: yup.number().required("El estado es obligatorio"),
});
