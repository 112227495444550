import { Circle } from "lucide-react";
import { useState } from "react";
import { TextField } from "../../../components";

export const SingleSelectionForm = () => {
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);
  const [correctAnswer, setCorrectAnswer] = useState<number | null>(null);

  const handleOptionChange = (index: number, value: string) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  return (
    <div className="bg-black my-6 p-6 rounded-lg shadow-md space-y-4">
      <div className="space-y-3">
        <div>
          <TextField
            className="relative w-full flex flex-row items-start justify-center rounded-lg border-[1px] outline-none text-base font-normal leading-6 text-black  placeholder-gray-500 placeholder:font-primaryBook mb-8"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Enter your question"
          ></TextField>
        </div>
        <div className="space-y-6">
          {options.map((option, index) => (
            <div key={index} className="flex items-center space-x-2">
              <input
                type="radio"
                name="correctAnswer"
                checked={correctAnswer === index}
                onChange={() => setCorrectAnswer(index)}
                className="h-4 w-4 text-rose-600 focus:ring-rose-500 border-gray-300"
              />
              <TextField
                className="relative w-full flex flex-row items-start justify-center rounded-lg border-[1px] outline-none text-base  font-normal leading-6  text-black placeholder-gray-500 placeholder:font-primaryBook"
                // value={question}
                onChange={(e) => handleOptionChange(index, e.target.value)}
                placeholder="Enter your question"
              ></TextField>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
