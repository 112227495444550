import { Plus } from "lucide-react";
import { Button } from "../../../../components";
import { FC, useState } from "react";
import { QuestionTypeSelection } from "../QuestionTypeSelection";
import { MultipleSelectionForm } from "../../MultipleSelection";
import { SingleSelectionForm } from "../../SingleSectionForm";
import { MatchingWordForm } from "../MatchingWordForm";

interface QuestionProps {
  currentQuestionNumber: number;
  numberOfQuestion: number;
  handleNext: () => void;
  handlePrevious: () => void;
}

interface WordSet {
  leftWords: string[];
  rightWords: string[];
  title: string;
}

export const Question: FC<QuestionProps> = ({
  currentQuestionNumber,
  numberOfQuestion,
  handleNext,
  handlePrevious,
}) => {
  const handleSave = (newWordSet: WordSet) => {
    // setWordSet(newWordSet);
    // setShowGame(true);
  };
  const [questionType, setQuestionType] = useState<string | null>(null);
  return (
    <div>
      <div className="flex items-center justify-between min-w-full">
        <h3 className="text-white text-2xl lg:text-3xl font-bold">
          Pregunta{" "}
          <span className="text-primary-50 text-2xl">
            {currentQuestionNumber}
          </span>
          /<span>{numberOfQuestion}</span>
        </h3>
        {questionType && (
          <Button
            onClick={() => {
              setQuestionType(null);
            }}
            color={"primary"}
          >
            Tipo de cambio
          </Button>
        )}
      </div>
      <div>
        {!questionType ? (
          <QuestionTypeSelection
            handleSelect={(type) => {
              setQuestionType(type);
            }}
          />
        ) : questionType === "multiple" ? (
          <MultipleSelectionForm />
        ) : questionType === "single" ? (
          <SingleSelectionForm />
        ) : (
          <MatchingWordForm onSave={handleSave} />
        )}
      </div>
      {questionType && (
        <div className="flex items-center justify-center gap-8">
          <Button
            disabled={currentQuestionNumber < 2 ? true : false}
            onClick={() => {
              handlePrevious();
            }}
            className="w-full"
            color="third"
          >
            Previous
          </Button>
          <Button
            onClick={() => {
              handleNext();
            }}
            className="w-full"
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
};
