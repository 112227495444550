import { useState } from "react";
import { LearningPathSessionDetailsModel } from "../../../data/models/learningPathSessionModel"
import { learninPathSessionDataService } from "../../../data/services/createSession.service"
import { useCustomEffect, useNavigation } from "../../hooks"
import toast from "react-hot-toast";
import { CreateSessionData } from "../../../domain/services/createSession.service";
import { AxiosError } from "axios";

export const LearningPathSessionDetails = () => {
    const { getQueryParam, navigateTo } = useNavigation();
    const [sessionDetails, setSessionDetails] = useState<LearningPathSessionDetailsModel>({} as LearningPathSessionDetailsModel)
    const sessionId = getQueryParam("session")

    const { getSessionDetails, updateSession } = learninPathSessionDataService()


    const createSessionSuccess = (data: CreateSessionData) => {
        toast.success("Sesión creada para la ruta de aprendizaje exitosamente")
        navigateTo("/confirm-session")
    }
    const createSessionError = (error: AxiosError) => {
        toast.error(error.message)
    }
    const cancelSession = () => {
        updateSession({ body: { learningPathId: sessionDetails.learning_path_uuid, name: sessionDetails.name, startAt: sessionDetails.starts_at, endAt: sessionDetails.ends_at, status: "cancelled", date: sessionDetails.date }, success: createSessionSuccess, error: createSessionError, learningPathSessionId: Number(sessionId) })

    }
    useCustomEffect(() => {
        // set date default value 
        const successGetLearningPathSession = (data: LearningPathSessionDetailsModel) => {
            setSessionDetails(data)
        }

        const errorGetLearningPathSession = () => {
        }

        getSessionDetails({
            success: successGetLearningPathSession,
            error: errorGetLearningPathSession,
            learningPathSessionId: Number(sessionId)
        });
    }, [])
    return {
        cancelSession,
        sessionDetails
    }
}