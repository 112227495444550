import { FC } from "react";
import { Rating } from "../../../../components";
import { useNavigation } from "../../../../hooks";

interface CourseCardProps {
  id: string;
  image: string;
  title: string;
  rating: number;
  terms: string[];
  productionType: string;
}

export const CourseCard: FC<CourseCardProps> = ({
  id,
  image,
  title,
  rating,
  terms,
  productionType,
}) => {
  const { navigateTo } = useNavigation();

  return (
    <div
      className="overflow-hidden hover:scale-105 mr-6 w-full px-4"
      onClick={() => navigateTo(`/course/${id}`)}
    >
      <div className="w-full max-w-[384px] mx-auto">
        <img src={image} className="h-[15rem] w-full rounded-3xl" />
        <div className="grid flex-col gap-2 p-6 place-items-start place-content-start">
          <div>
            <Rating
              id={"rating"}
              name={"rating"}
              value={rating}
              readOnly={true}
            />
          </div>
          <h2 className="text-white font-bold text-2xl">{title}</h2>
          <div className="mt-1">
            {terms.map((text) => (
              <span className="px-4 py-1 bg-gray-500 text-gray-200 rounded-3xl inline">
                {text}
              </span>
            ))}
          </div>
          <div>
            <span className="text-gray-400">{productionType}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
