import React, { useState } from "react";
import { Plus, Trash2, Save, X, MoveRight } from "lucide-react";
import { Button, TextField } from "../../../../components";

interface WordSet {
  leftWords: string[];
  rightWords: string[];
  title: string;
}

interface MatchingWordFormProps {
  onSave: (wordSet: WordSet) => void;
  initialSet?: WordSet;
}

export const MatchingWordForm = ({
  onSave,
  initialSet,
}: MatchingWordFormProps) => {
  const [title, setTitle] = useState(initialSet?.title || "");
  const [leftWords, setLeftWords] = useState<string[]>(
    initialSet?.leftWords || [""]
  );
  const [rightWords, setRightWords] = useState<string[]>(
    initialSet?.rightWords || [""]
  );

  const addWord = () => {
    setLeftWords([...leftWords, ""]);
    setRightWords([...rightWords, ""]);
  };

  const removeWord = (index: number) => {
    setLeftWords(leftWords.filter((_, i) => i !== index));
    setRightWords(rightWords.filter((_, i) => i !== index));
  };

  const updateLeftWord = (index: number, value: string) => {
    const newWords = [...leftWords];
    newWords[index] = value;
    setLeftWords(newWords);
  };

  const updateRightWord = (index: number, value: string) => {
    const newWords = [...rightWords];
    newWords[index] = value;
    setRightWords(newWords);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (leftWords.some((word) => !word) || rightWords.some((word) => !word)) {
      return;
    }
    onSave({ leftWords, rightWords, title });
  };

  const clearForm = () => {
    setTitle("");
    setLeftWords([""]);
    setRightWords([""]);
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-6 bg-black rounded-xl my-6 min-h-[10rem]">
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-4">
          <div className="space-y-4">
            {leftWords.map((_, index) => (
              <div key={index} className="flex gap-4 items-center">
                <div className="flex-1">
                  <TextField
                    className="relative w-full flex flex-row items-start justify-center rounded-lg border-[1px] outline-none text-base  font-normal leading-6  text-black placeholder-gray-500 placeholder:font-primaryBook"
                    // value={question}
                    onChange={(e) => {
                      //  handleOptionChange(index, e.target.value);
                    }}
                    placeholder="Enter your question"
                  ></TextField>
                </div>
                <div className="flex items-center">
                  <span className="text-gray-400 mx-4">
                    <MoveRight className="text-primary-50" />
                  </span>
                </div>
                <div className="flex-1">
                  <TextField
                    className="relative w-full flex flex-row items-start justify-center rounded-lg border-[1px] outline-none text-base  font-normal leading-6  text-black placeholder-gray-500 placeholder:font-primaryBook"
                    // value={question}
                    onChange={(e) => {}}
                    placeholder="Enter your question"
                  ></TextField>
                </div>
                {index > 0 && (
                  <button
                    type="button"
                    onClick={() => removeWord(index)}
                    className="p-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                  >
                    <Trash2 size={20} />
                  </button>
                )}
              </div>
            ))}
          </div>
          <Button
            onClick={addWord}
            color="secondary"
            className="text-primary-50"
          >
            Agregar par de palabras
          </Button>
        </div>
      </form>
    </div>
  );
};
