import { Routes, Route } from "react-router-dom";
import React from "react";
import {
  Catalog,
  Content,
  CreatePassword,
  Faqs,
  Home,
  Login,
  RecoverPassword,
  Register,
  Preview,
  Profile,
  Subscriptions,
  PageNotFound,
  Rentals,
  Dashboard,
  SubscriptionDetail,
  SearchPage,
  LearningPathsLanding,
  LearningPathDetail,
  LearningPathCalendar,
  CodeValidation,
  AdministratorSessionPage,
  StudentAccessmentPage,
  CreateQuizPage,
  TeacherGearBox,
  TeacherDashboard,
  CourseDetailsPage,
  QuizPage,
  Courses,
} from "../../pages";
import AdministrationDashboard from "../../pages/administrationDashboard/administrationDashboard";
import { PrivateRoute } from "./PrivateRoute";
import SessionPage from "../../pages/sessions/Session";
import CreateSesstion from "../../pages/CreateSession/CreateSession";
import SessionDetailsPage from "../../pages/sessionDetailsPage/sessionDetailsPage";

export const AppRoutes = () => (
  <Routes>
    {/* Public */}
    <Route path="/" element={<Home />} />
    <Route path="/home" element={<Home />} />
    <Route path="/content/:slug" element={<Content />} />
    <Route path="/code-validation" element={<CodeValidation />} />
    <Route path="/catalog" element={<Catalog />} />
    <Route path="/recover-password" element={<RecoverPassword />} />
    <Route path="/reset-password" element={<CreatePassword />} />
    <Route path="/signup" element={<Register />} />
    <Route path="/login" element={<Login />} />
    <Route path="/preview" element={<Preview />} />
    <Route path="/subscriptions" element={<Subscriptions />} />
    <Route path="/search" element={<SearchPage />} />
    <Route path="/faqs" element={<Faqs />} />
    <Route path="/learning-path-sessions" element={<SessionPage />} />
    <Route path="/rented-learning-path" element={<LearningPathsLanding />} />
    <Route path="/learning-path" element={<LearningPathsLanding />} />
    <Route path="/learning-path-detail" element={<LearningPathDetail />} />
    <Route path="/rentals" element={<Rentals />} />
    <Route path="*" element={<PageNotFound />} />

    {/* Private */}
    <Route
      path="/profile"
      element={
        <PrivateRoute>
          <Profile />
        </PrivateRoute>
      }
    />
    <Route
      path="/subscription-detail"
      element={
        <PrivateRoute>
          <SubscriptionDetail />
        </PrivateRoute>
      }
    />

    <Route
      path="/dashboard"
      element={
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      }
    />
    {/* Quiz routes */}

    {/* Student Quiz page */}
    <Route path="/student-dashboard" element={<StudentAccessmentPage />} />
    <Route path="/courses" element={<Courses />} />
    <Route path="/course/:id" element={<CourseDetailsPage />} />
    <Route path="/course/:courseId/quiz/:quizId" element={<QuizPage />} />

    {/* Professor quiz page */}
    <Route path="/professor-dashboard" element={<TeacherDashboard />} />
    <Route path="/teacher/assessment/create" element={<CreateQuizPage />} />
    <Route path="/teacher/gradebook" element={<TeacherGearBox />} />

    {/* Administration page */}
    <Route path="/admin/dashboard" element={<AdministrationDashboard />} />

    {/* Professior routes */}
    <Route path="/admin-learning-path-session" element={<CreateSesstion />} />
    <Route path="/confirm-session" element={<AdministratorSessionPage />} />
    <Route path="/session-details" element={<SessionDetailsPage />} />
    <Route path="/student-progress" element={<SessionDetailsPage />} />
  </Routes>
);
