import { FC } from "react";
import { Rating } from "../../../../components";
import { VideoPreview } from "../../../preview/components";

interface CourseVideoCardProps {
  poster: string;
  videoUrl: string;
  title: string;
  description: string;
}

export const CourseVideoCard: FC<CourseVideoCardProps> = ({
  poster,
  title,
  videoUrl,
  description,
}) => {
  const videoOption = {
    controls: true,
    controlBar: {
      pictureInPictureToggle: false,
      volumePanel: false,
      fullscreenToggle: false,
      currentTimeDisplay: true,
    },
    responsive: true,
    fluid: true,
    // autoplay: "play",
    sources: [
      {
        src: "https://res.cloudinary.com/drq7jke7x/video/upload/sp_full_hd_wifi/v1667083624/productions/sf1ng98jftieyb7ghrht.m3u8",
        type: "application/x-mpegURL",
      },
    ],
    poster:
      "https://res.cloudinary.com/drq7jke7x/image/upload/v1711035107/preview_images/uzjas70ipxzfpp8f82kw.png",
  };
  return (
    <div className="bg-black rounded-lg overflow-hidden p-6 w-full">
      <div className="w-full">
        <VideoPreview
          videoOptions={videoOption}
          imgPreview={
            "https://images.unsplash.com/photo-1568992687947-868a62a9f521?auto=format&fit=crop&w=1350&q=80"
          }
          format={""}
          terms={[]}
          duration={0}
          handlePlayerReady={() => {}}
          timeLeft={20}
          chapterName={"EL ASCENSOR"}
          productionName={"EL ASCENSOR"}
          currentSeasonNumber={1}
          nextChapterName={""}
          nextChapterImage={""}
          similarProductions={[]}
        />
      </div>
      <div className="grid flex-col gap-2 py-6 lg:p-6 place-items-start place-content-start pt-6">
        <h2 className="text-white font-bold text-lg lg:text-3xl">{title}</h2>
        <p className="text-white text-base font-primaryBook lg:text-lg">
          Dos conocidos empresarios del mundo "tech" se enfrentan a la
          negociación más importante de sus carreras.
        </p>
      </div>
    </div>
  );
};
