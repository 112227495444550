import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, FieldValues } from "react-hook-form";
import { newEvaluationSchema } from "../../validators/NewEvaluationSchema";
import { selectOptionEntity } from "../../../domain/entities";
import { useState } from "react";

export const useNewEvaluationViewModel = () => {
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange", resolver: yupResolver(newEvaluationSchema) });
  const [currentQuestionNumber, SetCurrentQuestionNumber] = useState(0)
  const [numberOfQuestion, setNumberOfQuestion] = useState(0)
  const [questions, setQuestions] = useState<any>([])

  const evaluationType: Array<selectOptionEntity> = [
    {
      id: "1",
      name: "Movie",
      label: "Movie",
    },
    {
      id: "2",
      name: "Serie",
      label: "Serie",
    },
    {
      id: "3",
      name: "Test",
      label: "text",
    },
  ];

  const courses: Array<selectOptionEntity> = [
    {
      id: "1",
      name: "Course 1",
      label: "Course 1",
    },
    {
      id: "2",
      name: "Course 2",
      label: "Course 2",
    },
    {
      id: "3",
      name: "Course 3",
      label: "Course 3",
    },
  ];

  const onSubmit = (data: FieldValues) => {
    const totalQuesNumber = parseInt(data.numberOfQuestion)
    setNumberOfQuestion(totalQuesNumber)
    const arrayOfObjects = Array.from({ length: totalQuesNumber }, (_, index) => ({
      id: index + 1,
      value: `Item ${index + 1}`,
    }));

    setQuestions(arrayOfObjects)
    parseInt(data.numberOfQuestion) > 0 && SetCurrentQuestionNumber(1)
  };

  const handleNext = () => {
    SetCurrentQuestionNumber(currentQuestionNumber + 1)
  }
  const handlePrevious = () => {
    SetCurrentQuestionNumber(currentQuestionNumber - 1)
  }

  return {
    handleSubmit,
    control,
    setValue,
    setError,
    onSubmit,
    errors,
    getValues,

    questions,
    handleNext,
    handlePrevious,
    evaluationType, courses,
    numberOfQuestion,
    currentQuestionNumber
  };
};
