import React, { FC, PropsWithChildren } from "react";

interface StatusProps {
  text: string;
}

export const Status: FC<PropsWithChildren<StatusProps>> = ({ text }) => {
  return (
    <span className="bg-secondary-50 px-4 py-1 rounded-tl-2xl rounded-bl-2xl absolute top-4 right-4 lg:inline text-lg text-white">
      {text}
    </span>
  );
};
