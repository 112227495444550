import dayjs from "dayjs";
import { Console } from "node:console";
import { start } from "node:repl";
import * as yup from "yup";

export const newEvaluationSchema = yup.object({
  title: yup.string().required("Se requiere ruta de aprendizaje"),
  timeLimit: yup.string().required("Se requiere límite de tiempo"),
  questionPageType: yup.string().optional(),
  tipo: yup.string().required("Se requiere tipo"),
  courseId: yup.string().required("Se requiere curso"),
  numberOfQuestion: yup.string().required("Se requiere el número de pregunta"),
});
