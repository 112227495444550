interface QuizProgressProps {
  total: number;
  current: number;
  answered?: number;
}

export function QuizProgress({ total, current, answered }: QuizProgressProps) {
  return (
    <div className="bg-black rounded-lg p-6">
      <div className="flex justify-between items-center mb-4">
        <span className="text-lg">
          Pregunta {current} de {total}
        </span>
        <span className="text-gray-400">{answered} respondidas</span>
      </div>
      <div className="w-full bg-gray-800 rounded-full h-2">
        <div
          className="bg-red-600 h-2 rounded-full transition-all"
          style={{ width: `${(current / total) * 100}%` }}
        />
      </div>
    </div>
  );
}
