import { httpService } from "../http/services/http";
import { Endpoints } from "./endpoints";
import { AdministratorLearningPathSessions, GetListLearningPathsSession } from "../../domain/services/administratorSession.service";
export function administratorSessionDataService(): AdministratorLearningPathSessions {
    const { get } = httpService();
    return {
        async getListLearningPathsSessions({ success, error }: GetListLearningPathsSession) {
            try {
                const response = await get(`${Endpoints.LearningPathsSessions}/session`)
                if (success) {
                    success(response.data.data)
                }
            } catch (err) {
                if (error) {
                    error(err)
                }
            }
        },


    }
}