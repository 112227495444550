import { Play, SquareCheckBig } from "lucide-react";
import { FC } from "react";
import { Status } from "../Status";

interface EvaluationCardProps {
  description: string;
  title: string;
  date: string;
  status: "Activa" | "Borrador";
  completed: string;
  total: string;
}

export const EvaluationCard: FC<EvaluationCardProps> = ({
  description,
  title,
  date,
  status,
  completed,
  total,
}) => {
  return (
    <div className="bg-gray-500 px-4 py-6 rounded-lg">
      <div className="flex justify-between">
        <div>
          <p className="text-white font-light">{title}</p>
          <p className="text-gray-300 font-medium text-sm">{description}</p>
        </div>
        <div>
          <Status status={status} />
        </div>
      </div>
      <div className="flex justify-between gap-1 mt-4">
        <p className="text-gray-300 font-medium text-sm">
          Completadas: {completed}/{total}
        </p>
        <p className="text-gray-300 font-medium text-sm">
          Fecha límite: {date}
        </p>
      </div>
    </div>
  );
};
