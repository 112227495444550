import {
  ChapterProgressInput,
  DashboardService,
  SaveProductionInput,
} from "../../domain/services/dashboard.service";
import { handleResponse } from "../../domain/shared";
import { httpService } from "../http/services/http";
import {
  MyStorytrainingInProgressModel,
  ProgressByAreaModel,
  SavedProductionsModel,
} from "../models";
import { Endpoints } from "./endpoints";
import { MedalEntity } from "../../domain/entities";
import { MiniMedalModel } from "../models/medal.model";

export interface SavedProductionsResponse {
  data: {
    data: SavedProductionsModel;
  };
}

export interface MyStorytrainingInProgressResponse {
  data: {
    data: {
      items: Array<MyStorytrainingInProgressModel>;
    };
  };
}

export interface MedalsUserResponse {
  data: {
    data: MiniMedalModel;
  };
}

export interface ProgressByAreaResponse {
  data: {
    data: {
      items: ProgressByAreaModel;
    };
  };
}

export function dashboardDataService(): DashboardService {
  const { get, post } = httpService();
  return {
    async getSavedProductionsDashboard({ success, error }: handleResponse) {
      try {
        const response: SavedProductionsResponse = await get(
          Endpoints.savedProductions
        );
        if (success) {
          success(response.data.data);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async getInProgressProductionsDashboard({
      success,
      error,
    }: handleResponse) {
      try {
        const response: MyStorytrainingInProgressResponse = await get(
          Endpoints.productionsInProgress
        );
        if (success) {
          success(response.data.data.items);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async postSavedProductionDashboard({
      productionUuid,
      success,
      error,
    }: SaveProductionInput) {
      try {
        await post(Endpoints.savedProductions, {
          production_uuid: productionUuid,
        });
        if (success) {
          success();
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async postChapterProgressDashboard({
      progress,
      chapterUuid,
      timeRanges,
      success,
      error,
    }: ChapterProgressInput) {
      try {
        await post(Endpoints.chapterProgress, {
          progress,
          chapter_uuid: chapterUuid,
          timeRanges,
        });
        if (success) {
          success();
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
    async getMedalsUserDashboard({ success, error }: handleResponse) {

    },
    async getProgressByArea({ success, error }: handleResponse) {
      try {
        const response: ProgressByAreaResponse = await get(
          Endpoints.progressByTerms
        );
        if (success) {
          success(response.data.data.items);
        }
      } catch (e) {
        if (error) {
          error(e);
        }
      }
    },
  };
}
