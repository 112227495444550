import {
  BookOpen,
  Users,
  SquareCheckBig,
  Clock4,
  CircleCheckBig,
  CircleX,
  TriangleAlert,
  Download,
  Filter,
} from "lucide-react";
import { Button, SelectControlled } from "../../components";
import CountCard from "../../components/CountCard/CountCard";
import { DefaultLayout } from "../../layouts";
import { ActivityCard } from "./components";
import { EvaluationCard } from "./components/EvaluationCard";
import { useNavigation } from "../../hooks";
import GradebookTable from "./components/GradeTable/GradeTable";
import { useState } from "react";
import { useForm } from "react-hook-form";
const dashBoardData: Array<{ title: string; icon: any; count: string }> = [
  {
    title: "Estudiantes Activos",
    icon: <Users color="#E30613" width={30} height={30} />,
    count: "4",
  },
  {
    title: "Aprobados",
    icon: <CircleCheckBig color="#047857" width={30} height={30} />,
    count: "45",
  },
  {
    title: "Reprobados",
    icon: <CircleX color="#E30613" width={30} height={30} />,
    count: "48",
  },
  {
    title: "Pendientes",
    icon: <TriangleAlert color="#f59e0b" width={30} height={30} />,
    count: "4",
  },
];

const recentActivities: Array<{
  description: string;
  title: string;
  time: string;
  accessmentType: string;
}> = [
  {
    description: "Completaste la evaluación",
    title: `Nueva evaluación creada`,
    time: "Hace 2 horas",
    accessmentType: "assessments",
  },
  {
    description: "Viste el video ",
    title: `Calificaciones actualizadas`,
    time: "Hace 2 horas",
    accessmentType: "course",
  },
];

interface mockStudentsTypes {
  id: string;
  name: string;
  email: string;
  course: string;
  assessments: {
    id: string;
    title: string;
    score: number;
    status: string;
    submittedAt: string | null;
  }[];
}

interface selectOptionEntity {
  id: string;
  name: string;
  label?: string;
}

const courseOption: selectOptionEntity[] = [
  {
    id: "1",
    name: "Cancelada",
    label: "cancelled",
  },
  {
    id: "2",
    name: "En curso",
    label: "in_progress",
  },
  {
    id: "3",
    name: "Confirmada",
    label: "confirmed",
  },
];

const mockStudents: mockStudentsTypes[] = [
  {
    id: "1",
    name: "Juan Pérez",
    email: "juan@example.com",
    course: "LA MEJOR ALTERNATIVA",
    assessments: [
      {
        id: "1",
        title: "Evaluación Final",
        score: 85,
        status: "passed" as const,
        submittedAt: "2024-03-15",
      },
      {
        id: "2",
        title: "Quiz: Conceptos Básicos",
        score: 75,
        status: "passed" as const,
        submittedAt: "2024-03-10",
      },
    ],
  },
  {
    id: "2",
    name: "María García",
    email: "maria@example.com",
    course: "EL ASCENSOR",
    assessments: [
      {
        id: "1",
        title: "Evaluación Final",
        score: 0,
        status: "pending" as const,
        submittedAt: null,
      },
      {
        id: "2",
        title: "Quiz: Conceptos Básicos",
        score: 65,
        status: "failed" as const,
        submittedAt: "2024-03-12",
      },
    ],
  },
];

const evaluationData: Array<{
  description: string;
  title: string;
  date: string;
  status: "Activa" | "Borrador";
  completed: string;
  total: string;
}> = [
  {
    description: "El Ascensor",
    title: `Liderazgo Efectivo`,
    date: "2024-03-20",
    status: "Activa",
    completed: "12",
    total: "20",
  },
  {
    description: "La Mejor Alternativa",
    title: `Conceptos de Negociación`,
    date: "2024-03-25",
    status: "Borrador",
    completed: "12",
    total: "20",
  },
];

export const TeacherGearBox = () => {
  const { navigateTo } = useNavigation();
  const { control } = useForm();
  const [students, setStudents] = useState<mockStudentsTypes[]>(mockStudents);
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc" as "asc" | "desc",
  });
  const handleUpdateGrade = (
    studentId: string,
    assessmentId: string,
    score: number
  ) => {
    setStudents(
      students.map((student) => {
        if (student.id === studentId) {
          return {
            ...student,
            assessments: student.assessments.map((assessment) => {
              if (assessment.id === assessmentId) {
                return {
                  ...assessment,
                  score,
                  status: score >= 70 ? "passed" : "failed",
                };
              }
              return assessment;
            }),
          };
        }
        return student;
      })
    );
  };

  return (
    <DefaultLayout>
      <div className="mx-4 lg:mx-16 ">
        <div className="flex justify-between items-center mb-8 ">
          <h2 className="text-lg lg:text-4xl text-white font-bold ">
            Libro de Calificaciones
          </h2>
          <div className="flex items-center gap-4">
            <Button
              onClick={() => {
                navigateTo("/teacher/assessment/create");
              }}
            >
              <span className="flex gap-4 items-center">
                <Download /> Exportar CSV
              </span>
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 ">
          {dashBoardData.map((item) => (
            <CountCard {...item} />
          ))}
        </div>

        <div className="flex items-center justify-end w-full mt-6">
          <div className="flex items-center justify-end w-[35rem] gap-6 ">
            <div className="w-full flex items-center gap-2">
              <Filter className="h-8 w-8 text-white" />
              <SelectControlled
                id={"status"}
                name="status"
                // label="Estado"
                placeholder="Elige tu Status"
                error={false}
                helperText={""}
                control={control}
                options={courseOption}
              />
            </div>

            <div className="w-[80%]">
              <SelectControlled
                id={"status"}
                name="status"
                // label="Estado"
                placeholder="Elige tu Status"
                error={false}
                helperText={""}
                control={control}
                options={courseOption}
              />
            </div>
          </div>
        </div>
        {/* Grade table */}
        <div className="my-6">
          <GradebookTable
            students={students}
            onUpdateGrade={handleUpdateGrade}
            sortConfig={sortConfig}
            onSort={setSortConfig}
          />
        </div>
      </div>
    </DefaultLayout>
  );
};
