import { FC } from "react";
import { DefaultLayout } from "../../layouts";
import {
  Button,
  CheckBoxControlled,
  Modal,
  PasswordFieldControlled,
  SelectControlled,
  TextFieldControlled,
  Breadcrumb,
} from "../../components";
import { useRegisterViewModel } from "../../viewModels";
import { StoryTrainingIcon } from "../../assets/Icons";
import { useNavigation } from "../../hooks";

export const Register: FC = () => {
  const {
    breadcrumbItemsElements,
    navigateTo,

    countriesData,
    legalData,

    showModalRegisterSuccess,
    handleCloseModal,

    handleSubmit,
    onSubmit,
    control,
    errors,
  } = useRegisterViewModel();
  return (
    <DefaultLayout>
      <div className="px-8 lg:px-14 hidden lg:block">
        <Breadcrumb breadcrumbItems={breadcrumbItemsElements} />
      </div>
      <div className="flex flex-col items-center max-w-screen-md mx-auto my-14 lg:my-20 px-8 lg:px-0 text-white text-center">
        <h1 className="text-2 lg:text-3.5 font-primaryBold ">
          ¡Te damos la bienvenida!
        </h1>
        <p className="text-base lg:text-2xl font-primaryBook ">
          Al crear tu cuenta podrás personalizar tu storytraining
        </p>

        <form className="mt-16 flex flex-col gap-y-6  max-w-inputForm">
          <TextFieldControlled
            id={"email"}
            name="email"
            label="Correo electrónico:"
            placeholder="Escribe aquí tu correo"
            error={!!errors.email}
            helperText={errors?.email?.message}
            control={control}
            required
          />
          <TextFieldControlled
            id={"name"}
            name="name"
            label="Nombres completos:"
            placeholder="Escribe aquí tus nombres"
            error={!!errors.name}
            helperText={errors?.name?.message}
            control={control}
            required
          />
          <TextFieldControlled
            id={"lastName"}
            name="lastName"
            label="Apellidos:"
            placeholder="Escribe aquí tus apellidos"
            error={!!errors.lastName}
            helperText={errors?.lastName?.message}
            control={control}
            required
          />
          {!!countriesData?.countries.length && (
            <SelectControlled
              id={"country"}
              name="country"
              label="País"
              placeholder="Elige tu país de origen"
              error={!!errors.country}
              helperText={errors?.country?.message}
              control={control}
              options={countriesData?.countriesOptions}
              defaultValue="none"
              required
            />
          )}
          <PasswordFieldControlled
            id={"password"}
            name="password"
            label="Contraseña:"
            placeholder="Ingresa tu contraseña"
            error={!!errors.password}
            helperText={errors?.password?.message}
            control={control}
            required
            useTooltip={true}
          />
          <CheckBoxControlled
            className="text-sm font-primarySemibold"
            id={"termsAndConditions"}
            name="termsAndConditions"
            label={
              <a href={legalData?.tyc?.file?.value} target="_blank">
                Acepto términos y condiciones
              </a>
            }
            error={!!errors.termsAndConditions}
            helperText={errors?.termsAndConditions?.message}
            control={control}
          />
          <CheckBoxControlled
            className="text-sm font-primarySemibold"
            id={"privacyPolicy"}
            name="privacyPolicy"
            label={
              <a href={legalData?.politics?.file?.value} target="_blank">
                Acepto la política de consulta y tratamiento de datos personales
              </a>
            }
            error={!!errors.privacyPolicy}
            helperText={errors?.privacyPolicy?.message}
            control={control}
          />
          <Button
            className="max-w-fit mx-auto mt-10"
            onClick={handleSubmit(onSubmit)}
            type="submit"
          >
            Crear cuenta
          </Button>
        </form>
      </div>
      <div className="text-center px-5 mb-10 ">
        <p className="text-white">¿Ya tienes una cuenta con nosotros?</p>
        <a
          className="text-red-600 cursor-pointer"
          onClick={() => navigateTo("/login")}
        >
          Ingresa aquí
        </a>
      </div>

      <Modal
        showModal={showModalRegisterSuccess}
        backgroundColor="white"
        onClose={handleSubmit(handleCloseModal)}
      >
        <div className="px-4 lg:px-8 text-center flex flex-col items-center justify-center gap-y-4 max-w-registerModal">
          <h3 className="text-2xl lg:text-2 font-primaryBold text-primary-100">
            ¡Ahora formas parte de storytraining!
          </h3>
          <StoryTrainingIcon />
          <p className="text-xl font-primaryBold text-primary-100">
            ¡Maravilloso!{" "}
          </p>
          <p className="text-xl font-primaryBook">
            Elige una de nuestras suscripciones para iniciar
          </p>
          <Button onClick={handleSubmit(handleCloseModal)}>
            Ver todo el catálogo
          </Button>
        </div>
      </Modal>
    </DefaultLayout>
  );
};
