import { FC, useState } from "react";
import Xarrow from "react-xarrows";

interface Connection {
  start: string;
  end: string;
}

interface selectedOptionCheck {
  id: string;
  text: string;
}
interface MatchingWordComponentProps {
  id: string;
  questionType: "multiple-choice" | "single-choice" | "matching-choice";
  question: string;
  options: string[];
  onAnswer: (
    questionId: string,
    questionType: "multiple-choice" | "single-choice" | "matching-choice",
    answer: string
  ) => void;
  selectedAnswer: selectedOptionCheck[];
}

export const MatchingWordComponent: FC<MatchingWordComponentProps> = () => {
  const leftWords = ["Dog", "Cat", "Bird"];
  const rightWords = ["Bark", "Meow", "Tweet"];

  const [connections, setConnections] = useState<Connection[]>([]);
  const [startWord, setStartWord] = useState<string | null>(null);

  const handleWordClick = (word: string, side: "left" | "right") => {
    if (!startWord) {
      if (side === "left") {
        setStartWord(word);
      }
    } else {
      if (side === "right") {
        const connectionExists = connections.some(
          (c) => c.start === startWord && c.end === word
        );
        if (!connectionExists) {
          setConnections((prev) => [...prev, { start: startWord, end: word }]);
        }
      }
      setStartWord(null);
    }
  };

  const removeConnection = (connectionToRemove: Connection) => {
    setConnections((prev) =>
      prev.filter(
        (connection) =>
          !(
            connection.start === connectionToRemove.start &&
            connection.end === connectionToRemove.end
          )
      )
    );
  };

  return (
    <div className="bg-black py-8 px-6">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-2xl font-medium mb-6 ">Word connection</h1>
        <div className="flex  mb-12 gap-x-32">
          <div className="flex flex-col space-y-8 w-full ">
            {leftWords.map((word) => (
              <div
                key={word}
                id={word}
                className={` cursor-pointer px-4 py-3 rounded-md flex items-center justify-center  hover:bg-secondary-100  ${
                  startWord === word ? "bg-primary-50" : "bg-secondary-300"
                }`}
                onClick={() => handleWordClick(word, "left")}
              >
                {word}
              </div>
            ))}
          </div>

          <div className="flex flex-col space-y-8 w-full">
            {rightWords.map((word) => (
              <div
                key={word}
                id={word}
                className="bg-secondary-300 hover:bg-secondary-100 cursor-pointer px-4 py-3 rounded-md flex items-center justify-center"
                onClick={() => handleWordClick(word, "right")}
              >
                {word}
              </div>
            ))}
          </div>
        </div>

        {connections.map((connection, index) => (
          <div
            className="cursor-pointer"
            onClick={() => removeConnection(connection)}
          >
            <Xarrow
              key={`${connection.start}-${connection.end}-${index}`}
              start={connection.start}
              end={connection.end}
              color="#E30613"
              strokeWidth={4}
              curveness={0.2}
            />
          </div>
        ))}

        <div className="mt-12 text-center text-gray-600 dark:text-gray-400 space-y-2">
          <p>
            Haga clic en una palabra a la izquierda, luego haga clic en una
            palabra coincidente a la derecha para conectarlas.
          </p>
          <p>Haga clic en cualquier línea para eliminar la conexión.</p>
        </div>
      </div>
    </div>
  );
};
