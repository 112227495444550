import { BookOpen, Play, SquareCheckBig } from "lucide-react";
import CountCard from "../../components/CountCard/CountCard";
import { DefaultLayout } from "../../layouts";
import { CourseSection, ActivityCard } from "./components";
import { JsxElement } from "typescript";

export const StudentAccessmentPage = () => {
  const dashBoardData: Array<{ title: string; icon: any; count: string }> = [
    {
      title: "Videos Vistos",
      icon: <Play color="#E30613" width={30} height={30} />,
      count: "4",
    },
    {
      title: "Evaluaciones Completadas",
      icon: <SquareCheckBig color="#E30613" width={30} height={30} />,
      count: "45",
    },
    {
      title: "Cursos En Progreso",
      icon: <BookOpen color="#E30613" width={30} height={30} />,
      count: "48",
    },
  ];

  const recentActivities: Array<{
    description: string;
    title: string;
    time: string;
    accessmentType: string;
  }> = [
    {
      description: "Completaste la evaluación",
      title: `"Effective Leadership"`,
      time: "Hace 2 horas",
      accessmentType: "assessments",
    },
    {
      description: "Viste el video ",
      title: `"Conflict Management - Chapter 3"`,
      time: "Hace 2 horas",
      accessmentType: "course",
    },
  ];
  return (
    <DefaultLayout>
      <div className="mx-4 lg:mx-16 mb-12">
        <h2 className="text-4xl text-white font-bold mb-6">
          Dashboard del Estudiante
        </h2>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
          {dashBoardData.map((item) => (
            <CountCard {...item} />
          ))}
        </div>
      </div>

      {/* course section */}
      <div className="mx-4 lg:mx-16 ">
        <h2 className="my-6 text-3xl text-white font-bold">
          Continúa Aprendiendo
        </h2>
        <CourseSection />
      </div>
      {/* Recent activity session */}
      <div className="bg-secondary-100 mx-4 lg:mx-16 my-12 p-6 rounded-md">
        <h2 className="text-3xl text-white font-bold mb-6">
          Actividad Reciente
        </h2>
        {recentActivities.map((recentActivity, index: number) => (
          <ActivityCard {...recentActivity} key={index} />
        ))}
      </div>
    </DefaultLayout>
  );
};
