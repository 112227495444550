import { FC, useEffect, useState } from "react";
import {
  StyledChapterCard,
  StyledContainerImage,
  StyledLinearProgress,
} from "./ChapterCard.styles";
import { PreviewChapterEntity } from "../../../domain/entities";
import { useWindowSize } from "../../hooks";
import { ReactComponent as PlayButtonIcon } from "../../assets/images/playButtonIcon.svg";

export interface CardHorizontalProps {
  className?: string;
  onClick?: () => void;
  chapterData: PreviewChapterEntity;
}

export const ChapterCard: FC<CardHorizontalProps> = ({
  className,
  onClick,
  chapterData,
}) => {
  const {
    uuid,
    name,
    description,
    videoPreview,
    imgPreview,
    duration,
    progress,
  } = chapterData;
  const { isDesktop } = useWindowSize();
  const progressPercentage = (progress * 100) / duration;
  return (
    <StyledChapterCard className={`StyledChapterCard ${className}`}>
      <StyledContainerImage onClick={onClick}>
        <PlayButtonIcon className="playButton" />
        <img
          src={imgPreview}
          alt={`Imagen capítulo ${name}`}
          title={`Imagen capítulo ${name}`}
        />
        <StyledLinearProgress
          variant="determinate"
          valueBuffer={100}
          value={progressPercentage > 100 ? 100 : progressPercentage}
        />
      </StyledContainerImage>

      <div className="flex flex-col justify-start gap-y-1 lg:gap-y-2  w-full">
        <h2
          className={`w-full text-white font-primaryBold flex items-start justify-start ${
            !isDesktop && name.length > 24 ? "text-xs" : "text-sm"
          } text-ellipsis  lg:text-xl`}
        >
          {!isDesktop && name.length > 40
            ? `${name.substring(0, 20)}...`
            : isDesktop && name.length > 40
            ? `${name.substring(0, 25)}...`
            : name}
        </h2>

        <p className="h-12 text-white text-xs text-leftoverflow-hidden lg:text-base font-primaryRegular">
          {!isDesktop && description.length > 40
            ? `${description.substring(0, 38)}...`
            : isDesktop && description.length > 40
            ? `${description.substring(0, 38)}...`
            : description}
        </p>

        <p className="text-primary-100 text-xs text-left lg:text-base font-primaryRegular">
          {Math.round(duration / 60)} minutos
        </p>
      </div>
    </StyledChapterCard>
  );
};
