import dayjs from "dayjs";
import { Breadcrumb, Button } from "../../components";
import { DefaultLayout } from "../../layouts";
import { LearningPathSessionDetails } from "../../viewModels/learningPathsSessionDetails";
import { useNavigation } from "../../hooks";
import { learninPathSessionDataService } from "../../../data/services/createSession.service";

const SessionDetailsPage = () => {
  const { navigateTo } = useNavigation();
  const { sessionDetails, cancelSession } = LearningPathSessionDetails();

  const breadcrumbItemsElements = [
    {
      name: "Inicio",
      url: "/",
    },
    {
      name: "Sesiones",
      url: "/confirm-session",
    },
    {
      name: "Sesión",
    },
  ];
  return (
    <DefaultLayout isAbsolute>
      <>
        <div className="px-4 lg:px-14 flex flex-col flex-1 justify-center py-5 mt-20 lg:mt-48">
          <div className="px-4 pt-10 lg:pt-0 lg:px-0  block">
            <Breadcrumb breadcrumbItems={breadcrumbItemsElements} />
          </div>
          <div className="flex flex-col max-w-[960px] flex-1 mx-auto ">
            <div className="p-4">
              <div className="flex flex-col items-stretch justify-start rounded-xl ">
                <div className="flex w-full min-w-72 grow flex-col items-stretch justify-center gap-1 pt-4 ">
                  <h2 className="flex text-left gap-x-4 items-center w-full justify-start text-2xl lg:text-3xl font-primaryBold text-white">
                    {sessionDetails.name}
                  </h2>
                  <p className="text-primary-50 font-medium">Confirmada</p>
                  <div className="flex items-end gap-3 justify-between">
                    <div className="flex flex-col gap-1">
                      {/* <p className="text-base font-normal leading-normal text-white">
                        by <span className="text-primary-50">Jane Smith</span>
                      </p> */}
                      {/* <p className="text-primary-50 text-base font-normal leading-normal">
                        Monday, April 12th, 2023
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-4  px-4 min-h-[72px] py-2">
              <div
                className="text-white flex items-center justify-center rounded-lg bg-primary-50 shrink-0 size-12"
                data-icon="Calendar"
                data-size="24px"
                data-weight="regular"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  fill="currentColor"
                  viewBox="0 0 256 256"
                >
                  <path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Zm-96-88v64a8,8,0,0,1-16,0V132.94l-4.42,2.22a8,8,0,0,1-7.16-14.32l16-8A8,8,0,0,1,112,120Zm59.16,30.45L152,176h16a8,8,0,0,1,0,16H136a8,8,0,0,1-6.4-12.8l28.78-38.37A8,8,0,1,0,145.07,132a8,8,0,1,1-13.85-8A24,24,0,0,1,176,136,23.76,23.76,0,0,1,171.16,150.45Z"></path>
                </svg>
              </div>
              <div className="flex flex-col justify-center">
                <p className="text-white text-base font-medium leading-normal line-clamp-1">
                  Date
                </p>
                <p className="text-primary-50 text-sm font-normal leading-normal line-clamp-2">
                  {`${dayjs(sessionDetails.date).format("dddd DD MMMM YYYY")}`},
                </p>
              </div>
            </div>
            <div className="flex items-center gap-4 px-4 min-h-[72px] py-2">
              <div
                className="text-white flex items-center justify-center rounded-lg bg-primary-50 shrink-0 size-12"
                data-icon="Clock"
                data-size="24px"
                data-weight="regular"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  fill="currentColor"
                  viewBox="0 0 256 256"
                >
                  <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm64-88a8,8,0,0,1-8,8H128a8,8,0,0,1-8-8V72a8,8,0,0,1,16,0v48h48A8,8,0,0,1,192,128Z"></path>
                </svg>
              </div>
              <div className="flex flex-col justify-center">
                <p className="text-white text-base font-medium leading-normal line-clamp-1">
                  Time
                </p>
                <p className="text-primary-50 text-sm font-normal leading-normal line-clamp-2">
                  {`${dayjs(sessionDetails.starts_at).format("HH:mm A")}`}{" "}
                  {"- "} {`${dayjs(sessionDetails.ends_at).format("HH:mm A")}`}
                  {/* {`${dayjs(session.date).format("MMMM")}`.slice(0, 3)}{" "}
                {dayjs(session.date).format("YYYY")}
                1:00 PM - 2:00 PM (PT) */}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-4  px-4 min-h-[72px] py-2">
              <div
                className="text-white flex items-center justify-center rounded-lg bg-primary-50 shrink-0 size-12"
                data-icon="Monitor"
                data-size="24px"
                data-weight="regular"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  fill="currentColor"
                  viewBox="0 0 256 256"
                >
                  <path d="M208,40H48A24,24,0,0,0,24,64V176a24,24,0,0,0,24,24H208a24,24,0,0,0,24-24V64A24,24,0,0,0,208,40Zm8,136a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V64a8,8,0,0,1,8-8H208a8,8,0,0,1,8,8Zm-48,48a8,8,0,0,1-8,8H96a8,8,0,0,1,0-16h64A8,8,0,0,1,168,224Z"></path>
                </svg>
              </div>
              <div className="flex flex-col justify-center">
                <p className="text-white text-base font-medium leading-normal line-clamp-1">
                  Format
                </p>
                <p className="text-primary-50 text-sm font-normal leading-normal line-clamp-2">
                  Live session
                </p>
              </div>
            </div>
            <div className="flex items-center gap-4  px-4 min-h-[72px] py-2">
              <div
                className=" flex items-center justify-center rounded-lg bg-primary-50 shrink-0 size-12"
                data-icon="Users"
                data-size="24px"
                data-weight="regular"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  fill="white"
                  viewBox="0 0 256 256"
                >
                  <path d="M117.25,157.92a60,60,0,1,0-66.5,0A95.83,95.83,0,0,0,3.53,195.63a8,8,0,1,0,13.4,8.74,80,80,0,0,1,134.14,0,8,8,0,0,0,13.4-8.74A95.83,95.83,0,0,0,117.25,157.92ZM40,108a44,44,0,1,1,44,44A44.05,44.05,0,0,1,40,108Zm210.14,98.7a8,8,0,0,1-11.07-2.33A79.83,79.83,0,0,0,172,168a8,8,0,0,1,0-16,44,44,0,1,0-16.34-84.87,8,8,0,1,1-5.94-14.85,60,60,0,0,1,55.53,105.64,95.83,95.83,0,0,1,47.22,37.71A8,8,0,0,1,250.14,206.7Z"></path>
                </svg>
              </div>
              <div className="flex flex-col justify-center">
                <p className="text-white text-base font-medium leading-normal line-clamp-1">
                  Registered Students
                </p>
                <p className="text-primary-50 text-sm font-normal leading-normal line-clamp-2">
                  {sessionDetails.users_count}/7
                </p>
              </div>
            </div>
            <div className="flex flex-col-reverse lg:flex-row px-4 py-3 justify-start gap-8">
              <Button
                className="w-full lg:w-64 "
                onClick={cancelSession}
                color="third"
              >
                Cancelar sesión
              </Button>
            </div>
          </div>
        </div>
      </>
    </DefaultLayout>
  );
};

export default SessionDetailsPage;
