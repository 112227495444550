import tw from "twin.macro";
import styled from "styled-components";
import { StyledMedalCard } from "../../components/MedalCard/MedalCard.styles";

export const StyledLearningPathDetailContainer = styled.section.attrs({
  className: "StyledLearningPathsLandingContainer",
})`
  ${tw`mt-5 lg:(bg-secondary-300) lg:(mx-16)`}
  h1 {
    ${tw`capitalize font-primaryBold text-2xl text-white text-center p-4 lg:(text-[32px] uppercase)`}
  }
`;

export const StyledMedalDetailCard = styled(StyledMedalCard).attrs((props) => ({
  className: "StyledMedalCard",
}))`
  ${tw`w-96 h-[721px] px-40`}
`;
