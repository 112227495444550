import { FC } from "react";

interface StatusProps {
  status: "Activa" | "Borrador";
}

export const Status: FC<StatusProps> = ({ status }) => {
  return (
    <span
      className="px-2 py-[8px] rounded-3xl  flex items-center justify-center h-6 text-xs"
      style={{
        backgroundColor: status === "Activa" ? "#d1fae5" : "#f3f4f6",
        color: status === "Activa" ? "#047857" : "#454545",
      }}
    >
      {status}
    </span>
  );
};
