import { DefaultLayout } from "../../layouts";
import { CourseSection } from "../studentAcessmentDashboard/components";

export const Courses = () => {
  return (
    <DefaultLayout>
      
      <div>
        <h2 className="font-primaryBold text-xl my-8 text-white text-center lg:text-3xl">
          CURSOS DISPONIBLE
        </h2>
        <div className="w-full lg:px-28 px-0 ">
          <CourseSection />
        </div>
      </div>
    </DefaultLayout>
  );
};
