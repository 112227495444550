import { SquareCheckBig } from "lucide-react";
import { CheckBox } from "../../../../components";
import { FC } from "react";

interface selectedOptionCheck {
  id: string;
  text: string;
}
interface SelectionOptionButtonProps {
  questionId: string;
  questionType: "multiple-choice" | "single-choice" | "matching-choice";
  onAnswer: (
    questionId: string,
    questionType: "multiple-choice" | "single-choice" | "matching-choice",
    answer: string
  ) => void;
  index: number;
  selectedAnswer: selectedOptionCheck[];
  option: string;
}
export const SelectionOptionButton: FC<SelectionOptionButtonProps> = ({
  questionType,
  questionId,
  onAnswer,
  index,
  selectedAnswer,
  option,
}) => {
  const answerCheck = (option: string, selected: selectedOptionCheck[]) => {
    const correct =
      selectedAnswer.filter((selected) => selected.text === option).length > 0
        ? true
        : false;
    return correct;
  };
  return (
    <button
      key={index}
      onClick={() => {
        onAnswer(questionId, questionType, option);
      }}
      className={`w-full text-left p-4 rounded-lg transition-colors flex items-center ${
        answerCheck(option, selectedAnswer)
          ? "bg-red-600 text-white"
          : "bg-secondary-300 hover:bg-secondary-100"
      }`}
    >
      {answerCheck(option, selectedAnswer) ? (
        <SquareCheckBig className="mr-4" />
      ) : (
        <CheckBox
          className={""}
          checked={answerCheck(option, selectedAnswer) ? true : false}
        />
      )}

      {option}
    </button>
  );
};
