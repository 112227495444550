import React from "react";
import { Breadcrumb, Button, Modal, SelectControlled } from "../../components";
import { DefaultLayout } from "../../layouts";
import { CreateSessionViewModel } from "../../viewModels/createSessionViewModel/createSessionViewModel";
import { StoryTrainingIcon } from "../../assets/Icons";
import { DateTimePicker } from "../../components/DateTimePicker";

const CreateSesstion = () => {
  const {
    handleSubmit,
    onSubmit,
    control,
    errors,
    setValue,
    getValues,

    cancelSession,
    showSuccessModal,
    closeSussionSuccessModal,
    listLearningPathsToRent,
    timeOptions,
    statusOptions,
    sessionId,
  } = CreateSessionViewModel();

  const breadcrumbItemsElements = [
    {
      name: "Inicio",
      url: "/",
    },
    {
      name: "Sesiones",
      url: "/confirm-session",
    },
    {
      name: "Programar sesiones",
    },
  ];
  return (
    <DefaultLayout>
      <div className="relative px-6  lg:px-14 block">
        <Breadcrumb breadcrumbItems={breadcrumbItemsElements} />
      </div>
      <div className="lg:mx-auto my-14 lg:my-20 px-8 lg:px-0 text-white text-center">
        <div>
          <h1 className="text-2 lg:text-3.5 font-primaryBold ">
            Sesión creada exitosamente
          </h1>
          <p className="text-base lg:text-2xl font-primaryBook ">
            La estudiante puede reservar en esta sesión.
          </p>
        </div>
        <form className="mt-16 flex flex-col gap-y-6 lg:max-w-md  m-auto">
          {!!listLearningPathsToRent.length && (
            <SelectControlled
              id={"learningPath"}
              name="learningPath"
              label="Rutas de aprendizaje"
              placeholder="Elige tu Learning Paths"
              error={!!errors.learningPath}
              helperText={errors?.learningPath?.message}
              control={control}
              options={listLearningPathsToRent.map((learningPath) => {
                return { name: learningPath.title, id: learningPath.uuid };
              })}
              required
            />
          )}

          {!!statusOptions.length && (
            <SelectControlled
              id={"status"}
              name="status"
              label="Estado"
              placeholder="Elige tu Status"
              error={!!errors.status}
              helperText={errors?.status?.message}
              control={control}
              options={statusOptions.filter(
                (item) => item.label !== "cancelled"
              )}
              required
            />
          )}
          <DateTimePicker
            id="date"
            required
            label="Fecha"
            onChangeDate={(value) => {
              setValue("date", value);
            }}
            error={!!errors.status}
            helperText={errors?.date?.message}
            shouldDisableDate={(date) => false}
            defaultValue={getValues("date")}
          />
          <SelectControlled
            id={"startAt"}
            name="startAt"
            label="Empezar en"
            placeholder="Elige tu Status"
            error={!!errors.status}
            helperText={errors?.startAt?.message}
            control={control}
            options={timeOptions}
            // defaultValue="none"
            required
          />
          <SelectControlled
            id={"endAt"}
            name="endAt"
            label="Fin en"
            placeholder="Elige tu Status"
            error={!!errors.endAt}
            helperText={errors?.endAt?.message}
            control={control}
            options={timeOptions}
            // defaultValue="none"
            required
          />

          <div className="flex items-center justify-between">
            {sessionId && (
              <Button
                color="third"
                className="max-w-fit mx-auto mt-10"
                onClick={cancelSession}
                type="button"
              >
                Cancelar sesión
              </Button>
            )}

            <Button
              className="max-w-fit mx-auto mt-10"
              onClick={handleSubmit(onSubmit)}
              type="button"
            >
              {sessionId ? "Editar sesión" : "Crear sesión"}
            </Button>
          </div>
        </form>
      </div>
      <Modal
        showModal={showSuccessModal}
        backgroundColor="white"
        onClose={handleSubmit(closeSussionSuccessModal)}
      >
        <div className="px-4 lg:px-8 text-center flex flex-col items-center justify-center gap-y-4 max-w-registerModal">
          <h3 className="text-2xl lg:text-2 font-primaryBold text-primary-100">
            ¡Ahora formas parte de storytraining!
          </h3>
          <StoryTrainingIcon />
          <p className="text-xl font-primaryBold text-primary-100">
            ¡Maravilloso!{" "}
          </p>
          <p className="text-xl font-primaryBook">
            Elige una de nuestras suscripciones para iniciar
          </p>
          <Button onClick={handleSubmit(closeSussionSuccessModal)}>
            Ver todo el catálogo
          </Button>
        </div>
      </Modal>
    </DefaultLayout>
  );
};

export default CreateSesstion;
