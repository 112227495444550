import { SelectionOptionButton } from "../SelectionOptionButton";

interface selectedOptionCheck {
  id: string;
  text: string;
}
interface QuizQuestionProps {
  id: string;
  questionType: "multiple-choice" | "single-choice" | "matching-choice";
  question: string;
  options: string[];
  onAnswer: (
    questionId: string,
    questionType: "multiple-choice" | "single-choice" | "matching-choice",
    answer: string
  ) => void;
  selectedAnswer: selectedOptionCheck[];
}

export function QuizQuestion({
  id,
  question,
  options,
  onAnswer,
  questionType,
  selectedAnswer,
}: QuizQuestionProps) {
  return (
    <div className="bg-black rounded-lg p-6">
      <h3 className="text-2xl font-medium mb-6">{question}</h3>
      <div className="space-y-4">
        {options.map((option, index) => (
          <SelectionOptionButton
            key={index}
            option={option}
            questionId={id}
            questionType={questionType}
            selectedAnswer={selectedAnswer}
            index={index}
            onAnswer={onAnswer}
          />
        ))}
      </div>
    </div>
  );
}
